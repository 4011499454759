import React, { useReducer, useEffect } from 'react';
import axios from 'axios';

// IMAGE
import redTelephone from '../../images/body-images/red-telephone.webp';

//REDUCER TO HANDLE STATES
import formReducer from '../../reducers/FormReducer';

// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


//INITIAL STATE
let initialContactFormState = {
  contactPerson: "",
  phoneNumber: "",
  emailAddress: "",
  countryState: "",
  Message: "",
};

function ContactUsSnippet(props) {

  const { confirmationModalPopUp, resultStatus, formKind} = props;

  let [state, dispatch] = useReducer (formReducer, initialContactFormState);

  useEffect(() => {

    const clearEventListener = () => {
        
    }

    return clearEventListener();
  }, []);


  const handleTextChange = (event) => {
    dispatch({
        type: "HANDLE CONTACT FORM INPUT",
        field: event.target.name,
        payload: event.target.value,
    });
};

  const handleFormSubmit = (event) => {
    let data = state;
    sendContactForm(data);
    event.preventDefault();
  }

  const sendContactForm = async(data) => {
    
    let response = await axios.post('https://mailer.ciscientific.com/rapiddoorsqld/contact-form', data)

    const result = response.data;
        if (result.status === 'Message Recieved' || 'Message Sent') {
            console.log('Email Sent!');
            resultStatus(true);
            formKind('calibration-contact-form');
            formReset();
            confirmationModalPopUp();
        }else {
            console.log("Error");
            resultStatus(false);
            formKind('calibration-contact-form');
            confirmationModalPopUp();
        }
  }

    // RESET FORM FUNCTION
    const formReset = () =>{
    dispatch({type: "HANDLE FORM RESET"});
  }



  return (
    <div className='contact-us-snippet-container'>
      <h3>Contact Us</h3>
      <p>Contact us anytime! Simply fill out the form and we'll get back to you as soon as we can</p>
      <div className='contact-us-snippet-content'>
        <div className='contact-us-snippet-image-div'><img src={redTelephone} alt='red-telephone.webp' /></div>
        <div className='contact-us-snippet-details'>
          <div>
          <form className='contact-us-snippet-form' method='POST' onSubmit={handleFormSubmit}>
                    <div className='contact-us-snippet-form-container'>
                        <div className='contact-us-snippet-form-field-items'>

                            <TextField
                            type="text"
                            name="contactPerson"
                            value={state.contactPerson}
                            className='contact-us-snippet-form-field' 
                            id="contact-person" 
                            label="Contact Person" 
                            variant="filled" 
                            margin="dense"
                            fullWidth
                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                            required
                            onChange={handleTextChange}
                            />

                            <TextField
                            type="number"
                            name="phoneNumber"
                            value={state.phoneNumber}
                            className='contact-us-snippet-form-field' 
                            id="contact-number" 
                            label="Contact Number" 
                            variant="filled" 
                            margin="dense"
                            fullWidth
                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                            required
                            onChange={handleTextChange}
                            />

                            <TextField
                            type='email'
                            name="emailAddress"
                            value={state.emailAddress}
                            className='contact-us-snippet-form-field' 
                            id="email-address" 
                            label="Email Address" 
                            variant="filled" 
                            margin="dense"
                            fullWidth
                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                            required
                            onChange={handleTextChange}
                            />

                            <TextField
                            type="text"
                            name="countryState"
                            value={state.countryState}
                            className='contact-us-snippet-form-field' 
                            id="country-state" 
                            label="Country/State" 
                            variant="filled" 
                            margin="dense"
                            fullWidth
                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                            required
                            onChange={handleTextChange}
                            />

                            <TextField
                            type='text'
                            name="Message"
                            value={state.Message}
                            className='contact-us-snippet-form-field'
                            id="message message-box" 
                            label="Message" 
                            variant="filled" 
                            multiline
                            rows={5}
                            margin="dense"
                            fullWidth
                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                            onChange={handleTextChange}
                            />
                        </div>
                        
                    </div>

                    <Button
                        className='contact-us-snippet-form-button hover1'
                        variant="outlined"
                        color="primary"
                        // size="large"
                        name='submit' 
                        value='submitRequest'
                        type='submit'
                        >
                        Submit
                    </Button>

                </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsSnippet