import React from 'react';

import redTelephone from '../../images/body-images/red-telephone.webp';

const ContactUsPageBanner = () => {
    return (
        <section className='contact-us-page-banner-main-container'>
            <div className="contact-us-page-banner-content-container">
                <div className="contact-us-page-image-container">
                    <img src={redTelephone} alt="" className="contact-us-page-image" />
                </div>
                <div className="contact-us-page-text-container">
                    <h1 className="contact-us-page-banner-header-text">Get In Touch</h1>
                    <p className="contact-us-page-banner-text">
                    Whether you're looking to install, maintain, or repair rapid doors, we're here to provide you with expert assistance and support. Our dedicated team is ready to address your inquiries.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default ContactUsPageBanner;