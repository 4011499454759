import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import RemaxDoorsDescription from './single-product-details-description/RemaxDoorsDescription';
import RemaxDoorsSpecification from './single-product-details-specification/RemaxDoorsSpecification';

function SingleProductPageRemaxDetails() {
  return(
    <Tabs
    defaultActiveKey="description"
    id="uncontrolled-tab-example"
    className="mb-3 single-product-page-details-tabs-component"
    fill
    >
    <Tab eventKey="description" title="DESCRIPTION">
        <RemaxDoorsDescription />
    </Tab>
    <Tab eventKey="specification" title="SPECIFICATION">
        <RemaxDoorsSpecification/>
    </Tab>
    </Tabs>
  )
}

export default SingleProductPageRemaxDetails