import React from "react";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary/ErrorBoundary";
import styles from "./resources/styles/ProductPage.module.scss";
import useFetch from "../../hooks/useFetch";

import ProductBanner from "./sections/banner-section/ProductBanner";
import ProductBody from "./sections/body-section/ProductBody";
import ErrorUI from "../../components/error-boundary/page-wide-error-boundary/ErrorUI";
import LoadingUI from "../../components/loading-ui/LoadingUI";
import Metadata from "../../components/metadata-component/Metadata";

const ProductPage = () => {
  const { slug } = useParams();
  const product_data = useFetch({
    method: "GET",
    url: `https://rdqcms.ciscalautomation.com/api/products/${slug}`,
  });

  if (product_data.loading) {
    return <LoadingUI />;
  }

  return (
    <Metadata seo={product_data.data.seo} slug={slug} type="product.item">
      <ErrorBoundary fallback={<ErrorUI />}>
        <main className={styles.pageContainer}>
          <ProductBanner product_data={product_data} />
          <ProductBody product_data={product_data} />
        </main>
      </ErrorBoundary>
    </Metadata>
  );
};

export default ProductPage;
