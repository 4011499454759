import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IconButton from '@mui/material/IconButton';


// ICONS
import rdqLogoLight from '../images/logos/rapid-doors-qld-logo-light.png';
import facebookLogo from '../images/logos/facebook-logo.svg';
import instagramLogo from '../images/logos/Instagram-logo.svg';
import linkedlnLogo from '../images/logos/linkedln-logo.svg';



function Footer() {
  return (
    <div className='footer-div'>
        
        <Container className='footer-container'>
            <Row className='footer-row justify-content-center'>
                <Col className='footer-company-info col-12 col-md-4'>
                    <div className='company-info-logo'><img src={rdqLogoLight} alt='company-logo'/></div>
                    <p>My Sparky Mate Rapid Doors has been providing top-notch service and maintenance for a variety of rapid doors. We are dedicated to helping our customers keep their rapid doors in top condition and operating smoothly, so they can focus on their business.</p>
                    <div className='footer-socials-div'>
                        <IconButton href="https://www.facebook.com/MySparkyMate/" className='footer-social-btn' target="_blank" rel="noreferrer"><img src=
                        {facebookLogo} className='footer-social-icon' alt='visit our page on facebook'/></IconButton>
                        <IconButton href='https://www.instagram.com/mysparkymate/' className='footer-social-btn' target="_blank" rel="noreferrer"><img src=
                        {instagramLogo} className='footer-social-icon' alt='visit our instagram page'/></IconButton>
                        <IconButton href='https://www.linkedin.com/company/my-sparky-mate' className='footer-social-btn' target="_blank" rel="noreferrer"><img src=
                        {linkedlnLogo} className='footer-social-icon' alt='go to our linked-in profile'/></IconButton>
                    </div>
                </Col>
    
                {/* <div footer-other-info> */}
                    <Col className='footer-links col-6 col-md-2'>
                        <h4>COMPANY</h4>
                        <ul className='footer-links-list'>
                            <li><a href='/'>Home</a></li>
                            <li><a href='/about-us'>About</a></li>
                            <li><a href='/contact'>Contact</a></li>
                            <li><a href='https://www.mysparkymate.com.au/privacy-policy/' target='_blank' rel="noreferrer">Privacy Policy</a></li>
                            <li><a href='https://www.ciscaltech.com/customer-feedback' target='_blank' rel="noreferrer">Customer feedback</a></li>
                        </ul>
                    </Col>
    
                    <Col className='footer-company col-6 col-md-2'>
                        <h4>BRANDS</h4>
                        <ul className='footer-company-list'>
                            <li><a href='/brands/DMF-Doors' target='_blank'>DMF Doors</a></li>
                            <li><a href='/brands/Efaflex-Doors' target='_blank'>Efaflex Doors</a></li>
                            <li><a href='/brands/Remax-Doors' target='_blank'>Remax Doors</a></li>
                            <li><a href='/brands/Safetech-Doors' target='_blank'>Safetech Doors</a></li>
                            <li><a href='/brands/Ansa-Doors' target='_blank'>Ansa Doors</a></li>
                            <li><a href='/brands/Mirage-Doors' target='_blank'>Mirage Doors</a></li>
                            <li><a href='/brands/Assa-Abloy-Doors' target='_blank'>Assa Abloy Doors</a></li>
                        </ul>
                    </Col>
    
                    <Col className='footer-contact col-12 col-md-2'>
                        <h4>CONTACT</h4>
                        <ul className='footer-contact-list'>
                            <li>Unit 3 54-58 Nealdon Drive Meadowbrook, QLD, 4131</li>
                            <li></li>
                            <li>1300 469 772</li>
                            <li>info@mysparkymate.com.au</li>
                            <li>Monday - Friday</li>
                            <li>7am - 3pm</li>

                            <li>After Hours Callout Emergency Service after 5pm</li>

                            <li>0436 465 696</li>
                        </ul>
                    </Col>
                {/* </div> */}
    
                <Col className='footer-copyright col-12'>
                    <p>© My Sparky Mate 2023 - All rights reserved - CISCAL Group of Companies.</p>
                </Col>

            </Row>
        </Container>
    </div>
  )
}

export default Footer