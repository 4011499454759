import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import MirageDoorsDescription from './single-product-details-description/MirageDoorsDescription';
import MirageDoorsSpecification from './single-product-details-specification/MirageDoorsSpecification';

function SingleProductPageMirageDetails() {
  return(
    <Tabs
    defaultActiveKey="description"
    id="uncontrolled-tab-example"
    className="mb-3 single-product-page-details-tabs-component"
    fill
    >
    <Tab eventKey="description" title="DESCRIPTION">
        <MirageDoorsDescription />
    </Tab>
    <Tab eventKey="specification" title="SPECIFICATION">
        <MirageDoorsSpecification/>
    </Tab>
    </Tabs>
  )
}

export default SingleProductPageMirageDetails