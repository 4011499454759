import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import SingleProductComponent from "./SingleProductComponent";
import styles from "./resources/css/ProductsComponentView.module.scss";
import dummyimg from "./../../../images/placeholder-image/placeholder-image.png";
import LoadingUI from "../../loading-ui/LoadingUI";

const ProductsListSection = () => {
  const { slug } = useParams();
  const { data, loading, error } = useFetch({
    method: "GET",
    url: `${process.env.REACT_APP_CMS_URL}/api/products?filters[categories][slug][$eq]=${slug}`,
  });

  if (error) throw Error("Error Loading data");
  if (loading) {
    return <LoadingUI />;
  }

  // Check if there are no items available
  if (data.data.length === 0) {
    return <div className={styles.noItemsMessage}>No items available.</div>;
  }

  return (
    <div className={styles.productsContainer}>
      {data?.data?.map((product, index) => {
        const { data } = product.attributes.product_images;

        const imageUrl = `${process.env.REACT_APP_CMS_URL}/${
          data === null ? "" : data[0]?.attributes.url
        }`;

        return (
          <SingleProductComponent
            key={product.id}
            image={data === null ? dummyimg : imageUrl}
            title={product.attributes.product_name}
            subtitle={product.attributes.product_subtitle}
            buttonText="View Details"
            productLink={`/products/${product.attributes.categories.data[0].attributes.slug}/${product.attributes.slug}`}
          />
        );
      })}
    </div>
  );
};

export default ProductsListSection;
