import React from "react";
import SingleProductComponent from "./SingleProductComponent";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import LoadingUI from "../../loading-ui/LoadingUI";
import styles from "./resources/css/ProductsComponentView.module.scss";
import dummyimg from "./../../../images/placeholder-image/placeholder-image.png";

const BrandsProductPageSection = () => {
  const { slug } = useParams();
  // const slug = "ultigroup";
  const { data, loading, error } = useFetch({
    method: "GET",
    url: `${process.env.REACT_APP_CMS_URL}/api/products?filters[brand][slug][$eq]=${slug}`,
  });

  if (error) throw Error("Error Loading Data");

  if (loading) {
    return <LoadingUI />;
  }

  if (error) {
    return (
      <div className={styles.errorMessage}>
        <p>Sorry, an error occurred while loading brands.</p>
        <p>Please try again later or contact support if the issue persists.</p>
      </div>
    );
  }

  if (data.data.length === 0) {
    return <div className={styles.noItemsMessage}>No items available.</div>;
  }

  return (
    <div className={styles.productsContainer}>
      {data?.data?.map((product_item, index) => {
        // console.log(product_item);
        const { data } = product_item.attributes.product_images;
        const imageUrl = `${process.env.REACT_APP_CMS_URL}/${
          data === null ? "" : data[0]?.attributes.url
        }`;
        return (
          <SingleProductComponent
            key={index}
            image={data === null ? dummyimg : imageUrl}
            title={product_item.attributes.product_name}
            subtitle={product_item.attributes.product_subtitle}
            buttonText="View Details"
            productLink={`/products/brands/${product_item.attributes.brand.data.attributes.slug}/${product_item.attributes.slug}`}
          />
        );
      })}
    </div>
  );
};

export default BrandsProductPageSection;
