import { React, useState, useEffect, useRef } from 'react';

// MUI
import Button from '@mui/material/Button';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

//BOOTSTRAP
import Modal from 'react-bootstrap/Modal';

//IMAGE
import redGradientBackground from '../../images/body-images/red-gradient-background-3.png';
import leftArrow from '../../images/icons/arrow-icon-left.svg';
import rightArrow from '../../images/icons/arrow-icon-right.svg';
import AssaAbloy1 from '../../images/products/assa-abloy-1.jpeg';
import AssaAbloy2 from '../../images/products/assa-abloy-2.jpeg';
import AssaAbloy3 from '../../images/products/assa-abloy-3.jpeg';
import AssaAbloy4 from '../../images/products/assa-abloy-4.jpeg';


function SingleProductPageAssaAbloyBanner({ CalibrationModalPopUp }) {


  const productImages = [ AssaAbloy2, AssaAbloy1, AssaAbloy3, AssaAbloy4]; // to handle the product mages array
    const [bottomPadding, setBottomPadding] = useState(0); //to set the bottom padding for the get quote btn (mobile view)
    const [displayImage, setDisplayImage] = useState([AssaAbloy2]); //handles the main image displayed. 


    const [show, setShow] = useState(false); //handle image fullscreen modal
    const handleClose = () => setShow(false); // to close modal
    const handleShow = () => setShow(true); // to open modal




    // handle the scroll event in image gallery using the arrows
    const imagesRef = useRef(null);

    const handleScrollLeft = () => {

        imagesRef.current.scrollBy({
            left: -200, // Set the amount to scroll by
            behavior: 'smooth'  // Use smooth scrolling
        });
    };

    const handleScrollRight = () => {
        imagesRef.current.scrollBy({
            left: 200, // Set the amount to scroll by
            behavior: 'smooth'  // Use smooth scrolling
        });
    };

    useEffect(() => {
        // Get the height of the bottom toolbar and URL bar
        const toolbarHeight = window.innerHeight - document.documentElement.clientHeight;
        setBottomPadding(toolbarHeight);
        
        // Listen for the resize and scroll events and update the padding
        const handleResize = () => {
        const newHeight = window.innerHeight - document.documentElement.clientHeight;
        setBottomPadding(newHeight);
        };

        const handleScroll = () => {
        const newHeight = window.innerHeight - document.documentElement.clientHeight + window.pageYOffset;
        setBottomPadding(newHeight);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);
        
        // Clean up the event listeners
        return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
        }

    }, []);



  return (

    <>
    <div className='singe-product-page-container'>
        <div className='single-product-page-blue-background'><img src={redGradientBackground}  alt='blue-background' /></div>

        <div className='single-product-page-content'>
            <div className='single-product-page-details'>
                
                <p>Your Trusted Partner for ASSA ABLOY Doors Services</p>
                <h2>ASSA ABLOY DOORS</h2>
                <p>
                    Rapid Doors Queensland is your premier partner for top-tier door services, proudly specializing in the finest ASSA ABLOY Doors. With a direct and professional approach, we offer unparalleled repairs, maintenance, and inspections that ensure your ASSA ABLOY Doors remain in impeccable condition, upholding their exceptional functionality and reliability.
                </p>
                <div className='single-product-page-image-gallery'>
                    <div className='single-product-page-image-gallery-arrow'><img src={leftArrow} alt=" " onClick={handleScrollLeft}/></div>
                    

                        <div className='single-product-page-images-container' ref={imagesRef}>
                            {productImages.map((image, i) => (
                            <div className='single-product-page-image-div' key={`image-${i}`}>
                                <img className='single-product-page-image' src={image} alt=" " onClick={e => setDisplayImage(image)}/>
                            </div>
                            ))}
                        </div>


                    <div className='single-product-page-image-gallery-arrow'><img src={rightArrow} alt=" " onClick={handleScrollRight} /></div>
                </div>

                <div className='single-product-page-get-a-quote-div' style={{ paddingBottom: `${bottomPadding}px` }}><Button onClick={CalibrationModalPopUp}  variant="contained" className='main-body-button' id='single-product-page-get-a-quote-btn' value='view-all' >GET A QUOTE</Button></div>

            </div>
            <div className='single-product-page-main-image-div'>
                <div className='single-product-page-main-image'>
                    <img src={displayImage} key={`display-image`} alt=' ' />

                    <div className='single-product-page-image-fullscreen-icon-div'>
                    <OpenInFullIcon className='single-product-page-image-fullscreen-icon' onClick={handleShow}/>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className='single-product-page-fullscreen-modal-div'>
    <Modal 
        show={show} 
        onHide={handleClose} 
        centered 
        animation={false} 
        size='lg'
        className='single-product-page-fullscreen-modal'
        >

        <Modal.Header closeButton/>
        <Modal.Body className='single-product-page-fullscreen-modal-body'>

        <div id='single-product-page-fullscreen-modal-img-div' style={{display: "flex", justifyContent: "center"  }}><img src={displayImage} className='single-product-page-fullscreen-modal-img img-fluid' alt=" "/></div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            Close
            </Button>
        </Modal.Footer> */}
        </Modal>
    </div>
    
    </>
    
  )
}

export default SingleProductPageAssaAbloyBanner