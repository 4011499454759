import React from "react";
import styles from "./resources/styles/ProductBanner.module.scss";

import ImageSlider from "./components/product-image-slider/ImageSlider";

const ProductBanner = ({ product_data }) => {
  const { data, loading, error } = product_data;

  if (error) {
    throw Error("Error encountered in ProductBanner.js");
  }

  const {
    product_images,
    product_name,
    product_subtitle,
    short_description,
    features,
    badges,
  } = data;

  const feature_items = features?.map(
    ({ feature_details, feature_title }, index) => {
      return (
        <li className={styles.featureItem}>
          <span className={styles.featureTitle}>{feature_title}</span>
          <span className={styles.featureDetails}>{feature_details}</span>
        </li>
      );
    }
  );

  const badge_items = badges?.map(({ badge_icon, badge_name }, index) => {
    return (
      <li className={styles.badgeItem}>
        <span className={styles.badgeContent}>
          <img
            src={
              badges === null
                ? ""
                : `${process.env.REACT_APP_CMS_URL}/${badge_icon?.url}`
            }
            alt={badge_name}
            className={styles.badgeImage}
          />
        </span>
      </li>
    );
  });

  return (
    <section className={styles.sectionContainer}>
      <div className={styles.sectionContent}>
        <div className={styles.imageContainer}>
          <ImageSlider
            product_images={product_images}
            loading={loading}
            error={error}
          />
        </div>

        <div className={styles.textContainer}>
          <h1 className={styles.productName}>{product_name}</h1>
          <h2 className={styles.productSubTitle}>{product_subtitle}</h2>
          <ul className={styles.badgesContainer}>{badge_items}</ul>
          <p className={styles.shortDescription}>{short_description}</p>
          <ul className={styles.featuresContainer}>{feature_items}</ul>
        </div>
      </div>
    </section>
  );
};

export default ProductBanner;
