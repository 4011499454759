import React from 'react';

const Loading = () => {
    return (
        <div className="loader">
        <span className="loader-text">Loading...</span>
          <span className="load"></span>
      </div>
    );
};

export default Loading;