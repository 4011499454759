import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

//MATERIAL UI IMPORTS
import { Button } from '@mui/material';

const PageNotFound = () => {
    return (
        <HelmetProvider>
            <section className='page-not-found-main-container'>
                {/**handles the META DATA of each page */}
                <Helmet>
                    <title>Page Not Found | Rapid Doors Queensland</title>
                    <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au/" />
                </Helmet>

                <div className="page-not-found-content-container">
                    <div className="page-not-found-404">
                        <h1 className="page-not-found-text">404</h1>
                    </div>
                    <div className="page-not-found-body-text">
                        <h2 className="page-not-found-header-text">Oops, this page is not found!</h2>
                        <p className="page-not-found-main-text">
                            Sorry, the page that you're looking for does not exist. It might have been removed, transfered, or temporarily unavailable.
                            </p>
                    </div>
                    <div className="page-not-found-button-container">
                        <Button as={Link} to={'/'} variant='contained' className='page-not-found-button glow-on-hover'>Take Me Home</Button>
                    </div>
                </div>
            </section>
        </HelmetProvider>
    );
};

export default PageNotFound;