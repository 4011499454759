import React from 'react';

//MATERIAL UI IMPORTS 
import { Button } from '@mui/material';

//REACT BOOTSRAP IMPORTS
import Modal from 'react-bootstrap/Modal';

//IMAGES
import paperPlaneBlue from '../images/icons/paperplane icon (blue).svg';
import paperPlaneGreen from '../images/icons/paperplane icon (green).svg';
import circleCheck from '../images/icons/check2-circle.svg';
import circleX from '../images/icons/x-circle.svg';

const ConfirmationModal = ({show, onHide, isSuccess, formType}) => {

    return (
        <Modal className="confirmation-modal-main-container" size="md" centered show={show} onHide={onHide} style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
            <div className="confirmation-modal-content-container" style={{backgroundColor: '#FAFAFA', borderRadius: '10px 10px 0 0'}}>
                <div className="confirmation-modal-banner" style={ isSuccess ? {background: 'linear-gradient(90deg, rgba(211,228,241,1) 61%, rgba(211,228,241,0.4) 100%)'} : isSuccess === false && formType === 'newsletter-form' ? {background: 'linear-gradient(90deg, rgba(147, 205, 195,1) 61%, rgba(147, 205, 195,0.4) 100%)'} : {background: 'linear-gradient(90deg, rgba(248,210,215,1) 61%, rgba(248,210,215,0.4) 100%)'}}>
                    <div className="confirmation-modal-image-container">
                        <img 
                        src={ 
                            isSuccess === true && formType === 'calibration-contact-form' 
                            ? circleCheck 
                            : isSuccess === false && formType === 'calibration-contact-form' ? circleX 
                            : isSuccess === true && formType === 'newsletter-form' ? paperPlaneBlue
                            : paperPlaneGreen
                        } 
                        alt="" 
                        className="confirmation-modal-image" />
                    </div>
                </div>
                <div className="confirmation-modal-body">
                    <div className="confirmation-modal-body-content-container">
                        <span className="confirmation-modal-header-text">
                            {
                            isSuccess === true && formType === 'calibration-contact-form' 
                            ? `Success!` 
                            : isSuccess === false && formType === 'calibration-contact-form' ? `Uh Oh, that didn't work!` 
                            : isSuccess === true && formType === 'newsletter-form' ? `Thank you for joining our mailing list!`
                            : `Thank you for your eagerness to be added on our mailing list!`
                            }
                        </span>
                        <p className="confirmation-modal-body-text">
                            {
                                isSuccess === true && formType === 'calibration-contact-form' 
                                ? `Thank you for submitting your form! We have received your information and will be in touch shortly. If you need immediate assistance, please contact us at 1300 469 772.` 
                                : isSuccess === false && formType === 'calibration-contact-form' ? `There was a problem sending the form. Kindly try again later. Thanks!` 
                                : isSuccess === true && formType === 'newsletter-form' ? `We can't wait to share exciting news, updates, and special offers with you. Keep an eye on your inbox for our next email, and if you have any questions or feedback, please don't hesitate to contact us.`
                                : `It seems like the email address you provided is already added on our mailing list. You can skip this step for now and watch out for exciting news, updates, and special offers just for you.`

                            }
                        </p>
                    </div>
                </div>
                <div className="confirmation-modal-footer">
                    <Button 
                        variant='contained' 
                        className='confirmation-modal-button' 
                        onClick={onHide} 
                        style={
                            isSuccess ? {backgroundColor: '#2d4694', color: '#FFFFFF'} 
                            :isSuccess === false && formType === 'newsletter-form' ? {backgroundColor: '#2c5f57', color: '#FFFFFF'}
                            : {backgroundColor: '#F8D2D7', color: '#DD2039'}
                        }
                    >
                        { 
                        isSuccess  === true && formType === 'calibration-contact-form' ? 'Continue' 
                        : isSuccess  === false && formType === 'calibration-contact-form' ? 'Try Again' 
                        : isSuccess  === true && formType === 'newsletter-form' ? 'Continue'
                        : 'Continue'
                        }
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;