import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


//COMPONENTS
import SingleProductPageDMFBanner from '../components/single-product-page/SingleProductPageDMFBanner';
import SingleProductPageDMFDetails from '../components/single-product-page/SingleProductPageDMFDetails';

function SingleProductPageDMF(prop) {

  //passing prop from app.js
  const { CalibrationModalPopUp } = prop;

  // Define a state variable in the parent component that holds the product ID:
  // const [product_Id, setProduct_Id] = useState(null);
  // const [productName, setProductName] = useState();

  //to make sure window will reload at the top coming from other pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //usage of the checking of freshchat callback function
  useEffect(() => {

    waitFreshchatWidget(() => {
      //console.log('Widget is now loaded. Run your function.');
      window.fcWidget.hide();
    });
  });

 
  //callback function to wait for freshchat widget to load
  // Widget not loaded, wait and check again
  const waitFreshchatWidget = (callback) => {

    if (typeof window.fcWidget !== 'undefined' && window.fcWidget.isLoaded()) {
      //console.log('Widget already loaded');
      callback();
  }
  else {
      
    //rerun the function again to recheck
    //console.log('Widget not yet loaded, check again');
    setTimeout(() => {
      waitFreshchatWidget( callback);}, 50);
  }
}


  return (

    <HelmetProvider>
      <div>
        {/**handles the META DATA of each page 
         * {`${productName || 'Autoclave Product Item'} | CISCAL Autoclave Australia`}
        */}
        <Helmet>
            <title>{`Brands - DMF Doors | Rapid Doors Queensland`}</title>
            <meta name="description" content="DMF Doors are the perfect solution for warehouses, factories, and other industrial applications. Our doors are designed to improve efficiency, productivity, and safety in the workplace. We offer a wide range of DMF Doors to choose from, including rapid roll doors, high-speed security doors, and large fold-up doors. We also offer installation, repair, and maintenance services for all of our DMF Doors. Contact us today to learn more about our services and how we can help you improve your workplace efficiency and safety."/>
            <meta 
              name="keywords" 
              content="DMF Doors,
              Rapid Doors Queensland,
              Industrial Doors,
              Rapid Roll Doors,
              High-Speed Security Doors,
              Large Fold-Up Doors,
              Warehouse Doors,
              Factory Doors,
              Manufacturing Doors,
              Food Processing Doors,
              Pharmaceutical Doors,
              Cold Storage Doors,
              Clean Rooms,
              Industrial Applications,"
            />
            <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au" />
        </Helmet>

        <SingleProductPageDMFBanner CalibrationModalPopUp = { CalibrationModalPopUp } />

        <div className='single-product-page-details-container'>
        <div className='single-product-page-details-content'>
        <SingleProductPageDMFDetails  />
        </div>
        </div>
      </div>
    </HelmetProvider>
    
  )
}

export default SingleProductPageDMF