import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Metadata = ({ children, slug, seo, type = "product.item" }) => {
  const {
    metaTitle,
    metaDescription,
    keywords,
    metaRobots,
    structuredData,
    metaViewport,
    cannonicalURL,
    metaImage,
    metaSocial,
  } = seo;

  const meta_social = metaSocial.map(
    ({ socialNetwork, title, description, image }) => {
      return { socialNetwork, title, description, image };
    }
  );

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={keywords} />
          <meta name="robots" content={metaRobots} />
          <meta name="viewport" content={metaViewport} />
          <link rel="canonical" href={cannonicalURL} />

          <meta property="og:title" content={meta_social[0]?.title} />
          <meta
            property="og:description"
            content={meta_social[0]?.description}
          />
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_CMS_URL}/${meta_social[0]?.image?.url}`}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content={type} />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_CMS_URL}/products/${slug}`}
          />
        </Helmet>
        ;{children}
      </>
    </HelmetProvider>
  );
};

export default Metadata;
