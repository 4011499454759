import React from "react";
import styles from "./resources/css/BrandProductBanner.module.scss";
const BrandProductBanner = ({ brand_data, error }) => {
  if (error) {
    throw Error("Error encountered in BrandProductBanner.js", error);
  }

  const { brand_name, brand_description, banner_image } = brand_data;
  const { url, alternativeText } = banner_image;

  return (
    <header className={styles.heroContainer}>
      <img
        src={`${process.env.REACT_APP_CMS_URL}/${url}`}
        alt={alternativeText}
        className={styles.backgroundImage}
      />
      <div className={styles.overlay}>
        <h1 className={styles.mainHeading}>{brand_name}</h1>
        <p className={styles.subHeading}>{brand_description}</p>
        <a href={"/contact"}>
          <button className={styles.ctaButton}>Talk to an Expert</button>
        </a>
      </div>
    </header>
  );
};

export default BrandProductBanner;
