import React from "react";
import PropTypes from "prop-types";
import styles from "./resources/css/ProductsMainPageBanner.module.scss";

const MainCategoryPageBanner = ({
  image,
  mainHeading,
  subHeading,
  buttonText,
  buttonLink,
}) => {
  return (
    <header className={styles.heroContainer}>
      <img src={image} alt={""} className={styles.backgroundImage} />
      <div className={styles.overlay}>
        <h1 className={styles.mainHeading}>{mainHeading}</h1>
        <p className={styles.subHeading}>{subHeading}</p>
        <a href={buttonLink}>
          <button className={styles.ctaButton}>{buttonText}</button>
        </a>
      </div>
    </header>
  );
};

MainCategoryPageBanner.propTypes = {
  image: PropTypes.string.isRequired,
  mainHeading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default MainCategoryPageBanner;
