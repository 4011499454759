import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// PRODUCT DETAILS
import  DMFDoorsDescription from './single-product-details-description/DMFDoorsDescription';
import DMFDoorsSpecification from './single-product-details-specification/DMFDoorsSpecification';





function SingleProductPageDetails() {


    return(

      <Tabs
      defaultActiveKey="description"
      id="uncontrolled-tab-example"
      className="mb-3 single-product-page-details-tabs-component"
      fill
      >
      <Tab eventKey="description" title="DESCRIPTION">
          <DMFDoorsDescription />
      </Tab>
      <Tab eventKey="specification" title="SPECIFICATION">
          <DMFDoorsSpecification/>
      </Tab>
      </Tabs>
    )
  
}

  

export default SingleProductPageDetails