import * as React from "react";

const DownloadIcon = ({ size = 46, color = "currentColor", ...props }) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M21.9 11.003c0-.11-.06-.22-.09-.33a4.162 4.162 0 0 0-.18-.57c-.05-.12-.12-.24-.18-.37s-.15-.3-.24-.44-.21-.21-.21-.29c0-.08-.2-.25-.31-.37-.11-.12-.21-.2-.32-.3l-.37-.33-.36-.24a3.675 3.675 0 0 0-.44-.23l-.39-.18a4.11 4.11 0 0 0-.5-.15 3 3 0 0 0-.41-.09h-.18a6 6 0 0 0-11.39-.11h-.18a3 3 0 0 0-.41.09 4.11 4.11 0 0 0-.5.15l-.39.18a3.678 3.678 0 0 0-.44.23l-.36.35-.37.31c-.11.1-.22.19-.32.3-.1.11-.21.25-.31.37s-.18.23-.26.36c-.08.13-.16.29-.24.44-.08.15-.13.25-.18.37a4.166 4.166 0 0 0-.18.57c0 .11-.07.22-.09.33-.062.313-.096.63-.1.95.005.305.035.61.09.91 0 .1.05.19.07.29.05.196.11.39.18.58l.12.29c.088.192.188.38.3.56l.14.22a.566.566 0 0 0 .05.08l.05.07a5 5 0 0 0 4 2 2 2 0 0 1 .59-1.41 2 2 0 0 1 1.41-.59c.352-.004.698.09 1 .27v-3.27a2 2 0 1 1 4 0v3.37a2 2 0 0 1 1-.27 2.05 2.05 0 0 1 1.44.61 2 2 0 0 1 .56 1.29 5 5 0 0 0 4-2l.05-.05a.566.566 0 0 0 .05-.08l.14-.22a5 5 0 0 0 .3-.56l.12-.29c.07-.19.13-.384.18-.58 0-.1.05-.19.07-.29a5.58 5.58 0 0 0 .09-.93 5.23 5.23 0 0 0-.1-1Z" />
    <path d="M14.31 16.38 13 17.64V12a1 1 0 0 0-2 0v5.59l-1.29-1.3a1.005 1.005 0 0 0-1.714.71 1.004 1.004 0 0 0 .294.71l3 3a1 1 0 0 0 1.4.01l3-2.9a1.002 1.002 0 0 0 .086-1.463.999.999 0 0 0-1.466.023Z" />
    <path d="M21.9 11.003c0-.11-.06-.22-.09-.33a4.162 4.162 0 0 0-.18-.57c-.05-.12-.12-.24-.18-.37s-.15-.3-.24-.44-.21-.21-.21-.29c0-.08-.2-.25-.31-.37-.11-.12-.21-.2-.32-.3l-.37-.33-.36-.24a3.675 3.675 0 0 0-.44-.23l-.39-.18a4.11 4.11 0 0 0-.5-.15 3 3 0 0 0-.41-.09h-.18a6 6 0 0 0-11.39-.11h-.18a3 3 0 0 0-.41.09 4.11 4.11 0 0 0-.5.15l-.39.18a3.678 3.678 0 0 0-.44.23l-.36.35-.37.31c-.11.1-.22.19-.32.3-.1.11-.21.25-.31.37s-.18.23-.26.36c-.08.13-.16.29-.24.44-.08.15-.13.25-.18.37a4.166 4.166 0 0 0-.18.57c0 .11-.07.22-.09.33-.062.313-.096.63-.1.95.005.305.035.61.09.91 0 .1.05.19.07.29.05.196.11.39.18.58l.12.29c.088.192.188.38.3.56l.14.22a.566.566 0 0 0 .05.08l.05.07a5 5 0 0 0 4 2 2 2 0 0 1 .59-1.41 2 2 0 0 1 1.41-.59c.352-.004.698.09 1 .27v-3.27a2 2 0 1 1 4 0v3.37a2 2 0 0 1 1-.27 2.05 2.05 0 0 1 1.44.61 2 2 0 0 1 .56 1.29 5 5 0 0 0 4-2l.05-.05a.566.566 0 0 0 .05-.08l.14-.22a5 5 0 0 0 .3-.56l.12-.29c.07-.19.13-.384.18-.58 0-.1.05-.19.07-.29a5.58 5.58 0 0 0 .09-.93 5.23 5.23 0 0 0-.1-1Z" />
    <path d="M14.31 16.38 13 17.64V12a1 1 0 0 0-2 0v5.59l-1.29-1.3a1.005 1.005 0 0 0-1.714.71 1.004 1.004 0 0 0 .294.71l3 3a1 1 0 0 0 1.4.01l3-2.9a1.002 1.002 0 0 0 .086-1.463.999.999 0 0 0-1.466.023Z" />
  </svg>
);

export default DownloadIcon;
