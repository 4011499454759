//INITIAL STATE
let initialState = {
    companyName: "",
    contactPerson: "",
    phoneNumber: "",
    emailAddress: "",
    countryState: "",
    postCode: "",
    companyWebsite: "",
    dateOfService: "",
    categoryption: "",
    productPurchase: false,
    inquiryFeedback: false,
    calibration: false,
    validation: false,
    repair: false,
    maintenance: false,
    others: false,
    promoCode: "",
    Message: "",
};

function FormReducer(state, action) {
    switch (action.type) {
        case "HANDLE CONTACT FORM INPUT":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE NEWSLETTER INPUT":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE NEWSLETTER INPUT2":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE BOOK CALIBRATION INPUT":
            return {
                ...state,
                [action.field]: action.payload,
            };
        case "HANDLE FORM RESET":
            return initialState;
        default:
            return state;
    }
}


export default FormReducer;