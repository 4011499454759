import React from "react";
import SomethingWentWrongIcon from "./resources/components/SomethingWentWrongIcon";
import styles from "./resources/styles/ErrorUI.module.scss";

const ErrorUI = ({ error, resetErrorBoundary }) => {
  return (
    <div className={styles.uiContainer}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <SomethingWentWrongIcon width={240} height={300} color="#2c2c2c" />
        </div>
        <div className={styles.textContainer}>
          <span className={styles.text}>Hmmm...Something went wrong!</span>
        </div>
      </div>
    </div>
  );
};

export default ErrorUI;
