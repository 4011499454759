import { useState } from "react";
import styles from "./resources/styles/ProductBody.module.scss";
import Markdown from "markdown-to-jsx";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { Info, DownloadForOffline } from "@mui/icons-material";

import DownloadCard from "./components/download-card/DownloadCard";

const ProductBody = ({ product_data }) => {
  const [value, setValue] = useState("1");

  const { data, error } = product_data;

  if (error) {
    throw Error("Error encountered in ProductBody.js");
  }

  const { product_details, downloads } = data;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const download_files = downloads?.map(({ name, url }, index) => {
    return (
      <DownloadCard
        name={name}
        url={`${process.env.REACT_APP_CMS_URL}/${url}`}
      />
    );
  });

  return (
    <section className={styles.sectionContainer}>
      <div className={styles.sectionContent}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="product information">
                <Tab
                  icon={<Info />}
                  iconPosition="start"
                  label="Product Details"
                  value="1"
                  disableRipple
                  disabled={product_details.length <= 0 ? true : false}
                  className={product_details ? styles.tab : styles.disabledTab}
                />
                <Tab
                  icon={<DownloadForOffline />}
                  iconPosition="start"
                  label="Downloads"
                  value="2"
                  disableRipple
                  disabled={downloads === null ? true : false}
                  className={downloads ? styles.tab : styles.disabledTab}
                />
              </TabList>
            </Box>
            <TabPanel value="1" className={styles.productDetails}>
              <Markdown>{product_details}</Markdown>
            </TabPanel>
            <TabPanel value="2" className={styles.downloads}>
              {download_files}
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </section>
  );
};

export default ProductBody;
