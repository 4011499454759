import React from 'react'

function EfaflexDoorsSpecification() {
  return (
    <section className='specification-main-section'>
      <p>No Data Available as of the moment. </p>

    </section>
  )
}

export default EfaflexDoorsSpecification