import React from 'react'

function SafetechDoorsSpecification() {
  return (
    <section className='specification-main-section'>
      <p>No Data Available as of the moment. </p>
    </section>
  )
}

export default SafetechDoorsSpecification