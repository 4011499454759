import React from 'react'

function AnsaDoorsDescription() {
  return (
    <div className='benchtop-autoclave-16-liter-description-container'>
      <div className='benchtop-autoclave-16-liter-description-content'>
        <p>
        Discover Unrivaled Service for your Ansa Doors, Available Only at Rapid Doors Australia!
        <br /><br />
        Welcome to a realm of unmatched industrial doors solutions, proudly crafted by Ansa Doors and expertly serviced through Rapid Doors Australia. Immerse yourself in a diverse range of products meticulously designed to meet the demands of modern industrial door requirements. Elevate security and versatility with Ansa Door's steel and aluminium roller shutters, ensuring a fortified security for your valuable assets. Explore the excellence of Ansa Door's sheeted and glazed bi-fold doors, creating seamless transitions between spaces while maximizing natural light. Embrace the ingenuity of our tilting doors, combining aesthetic appeal with functional accessibility. Revolutionize efficiency with our high-speed fabric doors, available in both rolling and folding configurations, designed to optimize workflow and minimize downtime.
        </p>

        <h3>Rapid Doors Queensland offers a wide range of services for Ansa Doors, including: </h3>

        <div className="rapid-doors-service-cards">
          <ul>
            <li><strong>Inspection</strong>Thorough assessments conducted by our experts to ensure your doors are functioning optimally and identify any potential issues.</li>
            <li><strong>Repair</strong>Swift and reliable door repairs, minimizing disruption and restoring optimal functionality.</li>
            <li><strong>Maintenance</strong>Proactive maintenance programs to extend the lifespan of your doors, guaranteeing continuous performance and peace of mind.</li>
          </ul>
        </div>

        <h3>Product Applications: </h3>
        <p>
          ANSA DOORS cater to a wide array of industries and structures, enhancing efficiency and security across various applications:
        </p>

        {/* <h3>Industrial Doors are commonly used in:</h3> */}
        <div className="industrial-doors-cards-container">
          <ul>
            <li>Warehouses and Storage Facilities</li>
            <li>Retail Establishments</li>
            <li>Manufacturing Plants</li>
            <li>Parking Facilities</li>
            <li>Healthcare Institutions</li>
            <li>Other industrial applications </li>
          </ul>
        </div>

        <h3>Ansa Door Models</h3>
        <div className="door-model-cards-container">
          <ul>
            <li>Roller Shutters</li>
            <li>Truck Shutters</li>
            <li>Specialty Doors</li>
          </ul>
        </div>
        <p>
          At Rapid Doors Australia, we understand that investing in the right door solution is crucial for your business. That's why we're committed to providing you with the best products and services that cater to your unique needs. Our team of experts is dedicated to helping you find the perfect door solution for your facility. Contact us today to learn more about our services and how we can help you improve your workplace efficiency and safety.
        </p>
      </div>
    </div>
  )
}

export default AnsaDoorsDescription