import React from "react";
import SingleBrandComponent from "./SingleBrandComponent";
import styles from "./resources/css/BrandsSection.module.scss";
import { useGlobalContext } from "../../../contexts/GlobalContextProvider";
import placeholderImage from "./../../../images/placeholder-image/placeholder-image.png";

import LoadingUI from "../../loading-ui/LoadingUI";

const BrandsSection = () => {
  const { product_brands } = useGlobalContext();
  const { data, loading, error } = product_brands;

  if (loading) {
    return <LoadingUI />;
  }

  if (error) {
    return (
      <div className={styles.errorMessage}>
        <p>Sorry, an error occurred while loading brands.</p>
        <p>Please try again later or contact support if the issue persists.</p>
      </div>
    );
  }

  const brand_products_items = data.data.map((item) => {
    const { id } = item;
    const { brand_name, slug, banner_image } = item.attributes;

    let imageUrl = banner_image?.data?.attributes?.url
      ? `${process.env.REACT_APP_CMS_URL}${banner_image.data.attributes?.url}`
      : placeholderImage;

    return (
      <SingleBrandComponent
        key={id}
        image={imageUrl}
        title={brand_name}
        brandLink={`products/brands/${slug}`}
      />
    );
  });

  return (
    <>
      <div className={styles.headingText}>Brands</div>
      <div className={styles.brandGrid}>{brand_products_items}</div>
    </>
  );
};

export default BrandsSection;
