import React from 'react';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


//COMPONENTS
import SingleProductPageEfaflexBanner from '../components/single-product-page/SingleProductPageEfaflexBanner';
import SingleProductPageEfaflexDetails from '../components/single-product-page/SingleProductPageEfaflexDetails';




function SingleProductPageEfaflex(prop) {

    //passing prop from app.js
  const { CalibrationModalPopUp } = prop;


  //to make sure window will reload at the top coming from other pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //usage of the checking of freshchat callback function
  useEffect(() => {

    waitFreshchatWidget(() => {

      //console.log('Widget is now loaded. Run your function.');
      window.fcWidget.hide();
    });

  });

 
  //callback function to wait for freshchat widget to load
  // Widget not loaded, wait and check again
  const waitFreshchatWidget = (callback) => {

    if (typeof window.fcWidget !== 'undefined' && window.fcWidget.isLoaded()) {
      //console.log('Widget already loaded');
      callback();
  }
  else {
      
    //rerun the function again to recheck
    //console.log('Widget not yet loaded, check again');
    setTimeout(() => {
      waitFreshchatWidget( callback);}, 50);
  }
}


  return (
    
    <HelmetProvider>
    <div>
      {/**handles the META DATA of each page */}
      <Helmet>
          <title>EFAFLEX Doors by Rapid Doors Queensland | Premier Provider of High-Speed Industrial Doors in Australia</title>
          <meta name="description" content="EFAFLEX Doors are the pinnacle of innovation in industrial access solutions. Rapid Doors Queensland is proud to be the premier provider of EFAFLEX Doors in Australia, offering a wide range of services, including installation, repair, and maintenance. Our team of experts will ensure that your EFAFLEX Doors are always operating at peak performance, keeping your operations secure and efficient."/>
          <meta 
            name="keywords" 
            content="efaflex doors,
            high-speed doors,
            industrial doors,
            warehouses,
            factories,
            distribution centers,
            rapid doors queensland,
            installation,
            repair,
            maintenance,
            security,
            efficiency,
            performance,
            customer satisfaction,"
          />
          <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au/" />
      </Helmet>

      <SingleProductPageEfaflexBanner CalibrationModalPopUp = { CalibrationModalPopUp } />

      <div className='single-product-page-details-container'>
      <div className='single-product-page-details-content'>
      <SingleProductPageEfaflexDetails  />
      </div>
      </div>
    </div>
  </HelmetProvider>


  )
}

export default SingleProductPageEfaflex