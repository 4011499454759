import React, { useReducer, useState, useEffect } from 'react';
import axios from 'axios';

//BOOTSTRAP IMPORTS
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//CUSTOM COMPONENTS
import LoadingComponent from '../components/Loading';

//MATERIAL UI IMPORTS
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import CloseIcon from '@mui/icons-material/Close';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

//REDUCER TO HANDLE STATES
import formReducer from '../reducers/FormReducer';

//INITIAL STATE
let initialContactFormState = {
    contactPerson: "",
    phoneNumber: "",
    emailAddress: "",
    countryState: "",
    promoCode: "",
    Message: "",
};


const RequestAQuoteModal = ({ show, onHide, resultStatus, formKind, confirmationModalPopUp }) => {
    //const { show, onHide, resultStatus, formKind, confirmationModalPopUp } = props;

    let [state, dispatch] = useReducer(formReducer, initialContactFormState);
    let [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        handleWindowSize();

        window.addEventListener('resize', handleWindowSize);

        const clearEventListener = () => {
            window.removeEventListener('resize', handleWindowSize);
        }

        return clearEventListener();
    }, []);

    const handleWindowSize = () => {
        setWindowSize([window.innerWidth, window.innerHeight]);
    }


    const handleTextChange = (event) => {
        dispatch({
            type: "HANDLE BOOK CALIBRATION INPUT",
            field: event.target.name,
            payload: event.target.value,
        });
    };

    const handleFormSubmit = (event) => {
        let data = state;
        sendContactForm(data);
        setLoading(true);
        event.preventDefault();
    }

    const copyAddressToClipboard = () => {
        let text = "Unit 3 54-58 Nealdown Drive, Meadowbrook, QLD, 4131";
        navigator.clipboard.writeText(text);
    }

    const copyPhoneToClipboard = () => {
        let text = "1300-469-772";
        navigator.clipboard.writeText(text);
    }

    const copyEmailToClipboard = () => {
        let text = "info@mysparkymate.com.au";
        navigator.clipboard.writeText(text);
    }



    const sendContactForm = async(data) => {
        try {
            let response = await axios.post('https://mailer.ciscientific.com/rapiddoorsqld/book-calibration-form', data)
            const result = response.data;
            if (result.status === 'Message Recieved' || 'Message Sent') {
                console.log('Email Sent!');
                setLoading(false);
                formReset();
                onHide();
                resultStatus(true);
                formKind('calibration-contact-form');
                confirmationModalPopUp();
            }
        } catch (error) {
            console.log("Error | " + error.message);
            resultStatus(false);
            formKind('calibration-contact-form');
            confirmationModalPopUp();
        }

    }

    // RESET FORM FUNCTION
    const formReset = () =>{
        dispatch({type: "HANDLE FORM RESET"}); //clears the input type=text fields.

        //clears the input type=checkbox fields.
        let checkboxItems = document.querySelectorAll(".contact-us-page-checkbox");
        for (const items of checkboxItems) {
            items.checked = false;
        }
    }


    return (
        <div className='book-calibration-modal-main'>
            <Modal 
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='book-calibration-modal-pop-up'
            >
                <CloseIcon sx={{position: 'absolute', right: '0'}} onClick={onHide}/>
                <div className="book-calibration-modal-content-container">
                    <div className="book-calibration-modal-left-container">
                        <div className="book-calibration-modal-left-container-text-container">
                            <span className="book-calibration-modal-left-container-header-text">Get in Touch!</span>
                            <p className="book-calibration-modal-left-container-text">We're just a message away. Reach out if you need help </p>
                            <div className="book-calibration-modal-left-container-contact-details-container">

                                <Tooltip title={ windowSize[0] >= 992 ? 'copy to clipboard' : 'copied to clipboard' }  followCursor={true} >
                                    <div className="book-calibration-modal-left-container-contact-items" onClick={copyAddressToClipboard}>
                                        <LocationOnOutlinedIcon className='book-calibration-left-container-contact-icons'/>
                                        <p className="book-calibration-left-container-contact-text" id='address'>
                                            { windowSize[0] >= 425 ? 
                                            'Unit 3 54-58 Nealdown Drive, Meadowbrook, QLD, 4131'
                                             : 'Click to copy address' 
                                            }
                                        </p>
                                    </div>
                                </Tooltip>

                                <Tooltip title={ windowSize[0] >= 992 ? 'copy to clipboard' : 'copied to clipboard' } followCursor={true} >
                                    <div className="book-calibration-modal-left-container-contact-items" onClick={copyPhoneToClipboard}>
                                        <CallOutlinedIcon className='book-calibration-left-container-contact-icons'/>
                                        <p className="book-calibration-left-container-contact-text" id='phone-number'>{ windowSize[0] >= 425 ? '1300-469-772' : 'Click to copy phone' }</p>
                                    </div>
                                </Tooltip>

                                <Tooltip title={ windowSize[0] >= 992 ? 'copy to clipboard' : 'copied to clipboard' } followCursor={true} >
                                    <div className="book-calibration-modal-left-container-contact-items" onClick={copyEmailToClipboard}>
                                        <EmailOutlinedIcon className='book-calibration-left-container-contact-icons'/>
                                        <p className="book-calibration-left-container-contact-text" id='email'>{ windowSize[0] >= 425 ? 'info@mysparkymate.com.au' : 'Click to copy email' }</p>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="book-calibration-modal-right-container">
                        <div className="book-calibration-modal-right-container-content-container">
                            <div className="book-calibration-modal-right-container-texts-container">
                                <h1 className="book-calibration-modal-right-container-header">Request a Quote</h1>
                                <p className="book-calibration-modal-right-container-main-text">Enquire or Arrange a Free Quote Now</p>
                            </div>
                            <form className='book-calibration-modal-form' method='POST' onSubmit={handleFormSubmit}>

                                <div className="book-calibration-form-left-right-container">
                                    <div className="book-calibration-form-left">


                                        <TextField
                                            type="text"
                                            name="contactPerson"
                                            value={state.contactPerson}
                                            className='homepage-contact-us-contact-form-field' 
                                            id="contact-person" 
                                            label="Contact Person" 
                                            variant="outlined" 
                                            margin="dense"
                                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                            required
                                            onChange={handleTextChange}
                                        />

                                        <TextField
                                            type='email'
                                            name="emailAddress"
                                            value={state.emailAddress}
                                            className='homepage-contact-us-contact-form-field' 
                                            id="email-address" 
                                            label="Email Address" 
                                            variant="outlined" 
                                            margin="dense"
                                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                            required
                                            onChange={handleTextChange}
                                        />
                                    </div>

                                    <div className="book-calibration-form-right">

                                        <TextField
                                            type="number"
                                            name="phoneNumber"
                                            value={state.phoneNumber}
                                            className='homepage-contact-us-contact-form-field' 
                                            id="contact-number" 
                                            label="Contact Number" 
                                            variant="outlined" 
                                            margin="dense"
                                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                            required
                                            onChange={handleTextChange}
                                        />
                                        
                                        <TextField
                                            type="text"
                                            name="countryState"
                                            value={state.countryState}
                                            className='homepage-contact-us-contact-form-field' 
                                            id="country-state outlined-required" 
                                            label="Country/State" 
                                            variant="outlined" 
                                            margin="dense"
                                            sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                            required
                                            onChange={handleTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="contact-us-page-promo-field-container">
                                    <span>Inquire about our promos</span>
                                    <TextField
                                        type='text'
                                        name="promoCode"
                                        value={state.promoCode}
                                        className='contact-us-page-form-field-item'
                                        id="promo-code" 
                                        label="Enter promo code (optional)" 
                                        variant="outlined" 
                                        multiline
                                        margin="dense"
                                        sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                        onChange={handleTextChange}
                                    />
                                </div>

                                <div className="book-calibration-message-container">
                                    <TextField
                                        type='text'
                                        name="Message"
                                        value={state.Message}
                                        className='homepage-contact-us-contact-form-field'
                                        id="message message-box" 
                                        label="Message" 
                                        variant="outlined" 
                                        multiline
                                        rows={5}
                                        margin="dense"
                                        sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                        onChange={handleTextChange}
                                    />
                                </div>

                                <div className="book-calibration-button-container">
                                    {
                                        loading ? 
                                        <LoadingComponent /> 
                                        : <Button
                                        className='book-calibration-button'
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        name='submit' 
                                        value='submitRequest'
                                        type='submit'
                                    >
                                        Submit
                                        </Button>
                                    }
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default RequestAQuoteModal;