import React from 'react';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


//COMPONENTS
import SingleProductPageRemaxBanner from '../components/single-product-page/SingleProductPageRemaxBanner';
import SingleProductPageRemaxDetails from '../components/single-product-page/SingleProductPageRemaxDetails';


function SingleProductPageRemax(prop) {


    //passing prop from app.js
    const { CalibrationModalPopUp } = prop;


    //to make sure window will reload at the top coming from other pages
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    //usage of the checking of freshchat callback function
    useEffect(() => {
  
      waitFreshchatWidget(() => {
  
        //console.log('Widget is now loaded. Run your function.');
        window.fcWidget.hide();
      });
  
    });
  
   
    //callback function to wait for freshchat widget to load
    // Widget not loaded, wait and check again
    const waitFreshchatWidget = (callback) => {
  
      if (typeof window.fcWidget !== 'undefined' && window.fcWidget.isLoaded()) {
        //console.log('Widget already loaded');
        callback();
    }
    else {
        
      //rerun the function again to recheck
      //console.log('Widget not yet loaded, check again');
      setTimeout(() => {
        waitFreshchatWidget( callback);}, 50);
    }
  }




  return (
    
    <HelmetProvider>
    <div>
      {/**handles the META DATA of each page */}
      <Helmet>
          <title>Rapid Doors Queensland | Remax Doors: Industrial Doors for Enhanced Efficiency and Excellence</title>
          <meta name="description" content="Rapid Doors Queensland is the premier service provider for Remax Doors in Australia. We offer a wide range of services for Remax Doors, including inspection, repair, and maintenance. Our team of seasoned professionals is dedicated to enhancing the functionality and efficiency of your Remax Doors, ensuring optimal temperature, dust, and pest control. Step into a realm of productivity and reliability with Rapid Doors Queensland - your gateway to unlocking the full potential of Remax Doors."/>
          <meta 
          name="keywords" 
          content="Remax Doors,
          Industrial Doors,
          High-Speed Roller Doors,
          Sectional Doors,
          Swing Doors,
          PVC Strip Doors,
          Warehouses,
          Distribution Centers,
          Manufacturing Plants,
          Factories,
          Food Processing Facilities,
          Pharmaceutical Laboratories,
          Cold Storage Units,
          Cleanrooms,
          Automotive Workshops,
          Retail Spaces,
          Temperature Control,
          Dust Mitigation,
          Pest Control,
          Security,
          Access Control,
          Operational Efficiency,
          Reliability,
          Excellence
          "/>
          <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au" />
      </Helmet>

      <SingleProductPageRemaxBanner CalibrationModalPopUp = { CalibrationModalPopUp } />

      <div className='single-product-page-details-container'>
      <div className='single-product-page-details-content'>
      <SingleProductPageRemaxDetails  />
      </div>
      </div>
    </div>
  </HelmetProvider>


  )
}

export default SingleProductPageRemax