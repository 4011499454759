import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//import formReducer from './reducers/FormReducer';

import { GlobalContextProvider } from "./contexts/GlobalContextProvider";

// COMPONENTS
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import RequestAQuoteModal from "./components/RequestAQuoteModal";
import ConfirmationModal from "./components/ConfirmationModal";

//PAGE FOR MAIN PAGE CATEGORY
import CategoryMainPage from "./pages/CategoryMainPage";
import CategoryProductPage from "./pages/CategoryProductPage";

// PAGES
import Homepage from "./pages/Homepage";
import ProductsMainPage from "./pages/ProductsMainPage";
import SingleProductPageAnsa from "./pages/SingleProductPageAnsa";
import SingleProductPageAssaAbloy from "./pages/SingleProductPageAssaAbloy";
import SingleProductPageDMF from "./pages/SingleProductPageDMF";
import SingleProductPageEfaflex from "./pages/SingleProductPageEfaflex";
import SingleProductPageMirage from "./pages/SingleProductPageMirage";
import SingleProductPageRemax from "./pages/SingleProductPageRemax";
import SingleProductPageSAfetech from "./pages/SingleProductPageSafetech";
import ProductPage from "./pages/product-page/ProductPage";
import BrandProductPage from "./pages/BrandProductPage";

import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import PageNotFound from "./pages/PageNotFound";

//FOOTER LINKS
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LoadingUI from "./components/loading-ui/LoadingUI";
import ErrorUI from "./components/error-boundary/page-wide-error-boundary/ErrorUI";
import DownloadCard from "./pages/product-page/sections/body-section/components/download-card/DownloadCard";

function App() {
  //FOR REQUEST A QUOTE MODAL
  const [calibrationModalShow, setCalibrationModalShow] = useState(false);
  const CalibrationModalPopUp = () => setCalibrationModalShow(true);
  const CalibrationModalHide = () => setCalibrationModalShow(false);

  // FOR NEWSLETTER MODAL
  const [newsletterModalShow, setNewsletterModalShow] = useState(false);
  const NewsletterModalHide = () => setNewsletterModalShow(false);

  //FOR CONFIRMATION MODAL
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formType, setFormType] = useState("");

  const confirmationModalPopUp = () => setConfirmationModalShow(true);
  const confirmationModalHide = () => setConfirmationModalShow(false);

  return (
    <div className="App">
      <GlobalContextProvider>
        <BrowserRouter>
          <NavigationBar />
          <Routes>
            <Route
              path="/"
              element={
                <Homepage
                  CalibrationModalPopUp={CalibrationModalPopUp}
                  confirmationModalPopUp={confirmationModalPopUp}
                  resultStatus={setIsSuccess}
                  formKind={setFormType}
                />
              }
            />

            <Route path="/chez" element={<DownloadCard />} />

            <Route path="/products" element={<CategoryMainPage />} />
            <Route path="/products/:slug" element={<CategoryProductPage />} />
            <Route path="/products/:slug/:slug" element={<ProductPage />} />
            <Route
              path="/products/brands/:slug/:slug"
              element={<ProductPage />}
            />
            <Route
              path="/products/brands/:slug"
              element={<BrandProductPage />}
            />

            <Route
              path="/rapid-auto-roll-and-high-speed-doors"
              element={<CategoryProductPage />}
            />

            {/* <Route path="/brands" element={<ProductsMainPage />} /> */}

            <Route
              path="/brands/Ansa-Doors"
              element={
                <SingleProductPageAnsa
                  CalibrationModalPopUp={CalibrationModalPopUp}
                />
              }
            />

            <Route
              path="/brands/Assa-Abloy-Doors"
              element={
                <SingleProductPageAssaAbloy
                  CalibrationModalPopUp={CalibrationModalPopUp}
                />
              }
            />

            <Route
              path="/brands/DMF-Doors"
              element={
                <SingleProductPageDMF
                  CalibrationModalPopUp={CalibrationModalPopUp}
                />
              }
            />

            <Route
              path="/brands/Efaflex-Doors"
              element={
                <SingleProductPageEfaflex
                  CalibrationModalPopUp={CalibrationModalPopUp}
                />
              }
            />

            <Route
              path="/brands/Mirage-Doors"
              element={
                <SingleProductPageMirage
                  CalibrationModalPopUp={CalibrationModalPopUp}
                />
              }
            />

            <Route
              path="/brands/Remax-Doors"
              element={
                <SingleProductPageRemax
                  CalibrationModalPopUp={CalibrationModalPopUp}
                />
              }
            />

            <Route
              path="/brands/Safetech-Doors"
              element={
                <SingleProductPageSAfetech
                  CalibrationModalPopUp={CalibrationModalPopUp}
                />
              }
            />

            <Route path="/about-us" element={<AboutUsPage />} />

            <Route
              path="/contact"
              element={
                <ContactUsPage
                  confirmationModalPopUp={confirmationModalPopUp}
                  resultStatus={setIsSuccess}
                  formKind={setFormType}
                />
              }
            />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
        <Footer />

        <RequestAQuoteModal
          show={calibrationModalShow}
          onHide={CalibrationModalHide}
          confirmationModalPopUp={confirmationModalPopUp}
          resultStatus={setIsSuccess}
          formKind={setFormType}
        />

        <ConfirmationModal
          show={confirmationModalShow}
          onHide={confirmationModalHide}
          isSuccess={isSuccess}
          formType={formType}
        />
      </GlobalContextProvider>
    </div>
  );
}

export default App;
