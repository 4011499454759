import React from "react";
import SingleCategoryComponent from "./SingleCategoryComponent";
import styles from "./resources/css/ProductsCategoryComponent.module.scss";
import { useGlobalContext } from "../../../contexts/GlobalContextProvider";
import placeholderImage from "./../../../images/placeholder-image/placeholder-image.png";

import LoadingUI from "../../loading-ui/LoadingUI";

const CategoryListSection = () => {
  const { product_categories } = useGlobalContext();

  const { data, loading, error } = product_categories;

  if (loading) {
    return <LoadingUI />;
  }

  if (error) {
    return (
      <div className={styles.errorMessage}>
        <p>Sorry, an error occurred while loading brands.</p>
        <p>Please try again later or contact support if the issue persists.</p>
      </div>
    );
  }

  const product_categories_items = data.data.map((item) => {
    const { id, attributes } = item;
    const { category_name, slug, category_image } = attributes;

    let imageUrl = category_image?.data?.attributes?.url
      ? `${process.env.REACT_APP_CMS_URL}${category_image.data.attributes?.url}`
      : placeholderImage;

    return (
      <SingleCategoryComponent
        key={id}
        image={imageUrl}
        title={category_name}
        categoryLink={`/products/${slug}`}
      />
    );
  });

  return (
    <>
      <h2 className={styles.headingText}>Categories</h2>
      <div className={styles.categoryGrid}>{product_categories_items}</div>;
    </>
  );
};

export default CategoryListSection;
