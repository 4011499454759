import React from 'react';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


//COMPONENTS
import SingleProductPageAnsaBanner from '../components/single-product-page/SingleProductPageAnsaBanner';
import SingleProductPageAnsaDetails from '../components/single-product-page/SingleProductPageAnsaDetails';


function SingleProductPageAnsa(prop) {
    

    //passing prop from app.js
  const { CalibrationModalPopUp } = prop;


  //to make sure window will reload at the top coming from other pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //usage of the checking of freshchat callback function
  useEffect(() => {

    waitFreshchatWidget(() => {

      //console.log('Widget is now loaded. Run your function.');
      window.fcWidget.hide();
    });

  });

 
  //callback function to wait for freshchat widget to load
  // Widget not loaded, wait and check again
  const waitFreshchatWidget = (callback) => {

    if (typeof window.fcWidget !== 'undefined' && window.fcWidget.isLoaded()) {
      //console.log('Widget already loaded');
      callback();
  }
  else {
      
    //rerun the function again to recheck
    //console.log('Widget not yet loaded, check again');
    setTimeout(() => {
      waitFreshchatWidget( callback);}, 50);
  }
}


  return (
    
    <HelmetProvider>
    <div>
      {/**handles the META DATA of each page */}
      <Helmet>
          <title>Rapid Doors Queensland | Ansa Doors Repair and Maintenance Services</title>
          <meta name="description" content="Rapid Doors Queensland is the leading provider of Ansa Doors repair and maintenance services in Australia. We offer a wide range of services, including inspection, repair, and maintenance, to keep your Ansa Doors in top condition. Contact us today to learn more about our services and how we can help you improve your workplace efficiency and safety."/>
          <meta name="keywords" content="ansa doors, ansa doors repair, ansa doors maintenance, rapid doors queensland, industrial doors, warehouse doors, retail doors, manufacturing doors, parking doors, healthcare doors, specialty doors, roller shutters, truck shutters"/>
          <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au/" />
      </Helmet>

      <SingleProductPageAnsaBanner CalibrationModalPopUp = { CalibrationModalPopUp } />

      <div className='single-product-page-details-container'>
      <div className='single-product-page-details-content'>
      <SingleProductPageAnsaDetails  />
      </div>
      </div>
    </div>
  </HelmetProvider>

  )
}

export default SingleProductPageAnsa