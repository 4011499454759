import React from "react";
import MainCategoryPageBanner from "../components/products-main-page/products-main-page-banner/MainCategoryPageBanner";
import bannerImage from "../components/products-main-page/products-main-page-banner/resources/images/bannerImage.jpg";
import ProductsSecondTitleBanner from "../components/products-main-page/products-second-title-banner/ProductsSecondTitleBanner";
import ErrorBoundary from "../components/error-boundary/ErrorBoundary";
import ErrorUI from "../components/error-boundary/page-wide-error-boundary/ErrorUI";
import BrandsSection from "../components/products-main-page/brands-section/BrandsSection";
import CategoryListSection from "../components/products-main-page/category-list-section/CategoryListSection";
const CategoryMainPage = () => {
  const paragraphs = [
    "As Australia's premier provider of specialized industrial doors, our unparalleled expertise and extensive experience in door design and production guarantee a tailored solution that perfectly fits your specific needs.",
    "Discover how to elevate operational performance, safety, and protection in your enterprise.",
  ];

  return (
    <ErrorBoundary fallback={<ErrorUI />}>
      <div>
        <MainCategoryPageBanner
          image={bannerImage}
          mainHeading="Door Systems"
          subHeading="Looking for reliable industrial door systems to manage temperature, hygiene, or security? Our cutting-edge solutions prioritize safety and productivity. Trust us to provide the solutions you need."
          buttonText="TALK TO AN EXPERT"
          buttonLink="/contact"
        />
        <ProductsSecondTitleBanner
          subHeading="Your Collaborative Ally"
          heading="Unlocking Pathways to an Enhanced Tomorrow"
          paragraphs={paragraphs}
        />
        <BrandsSection />
        <CategoryListSection />
      </div>
    </ErrorBoundary>
  );
};

export default CategoryMainPage;
