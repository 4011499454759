import React from "react";
import styles from "./resources/css/ProductsSecondTitleBanner.module.scss";

const ProductsSecondTitleBanner = ({ subHeading, heading, paragraphs }) => {
  return (
    <div className={styles.container}>
      <div className={styles.line}></div>
      <h4 className={styles.subHeading}>{subHeading}</h4>
      <h1 className={styles.heading}>{heading}</h1>
      {paragraphs.map((text, index) => (
        <p key={index} className={styles.paragraph}>
          {text}
        </p>
      ))}
    </div>
  );
};

export default ProductsSecondTitleBanner;
