import React from "react";
import styles from "./resources/DownloadCard.module.scss";
import DownloadIcon from "./icons/DownloadIcon";

const DownloadCard = ({ name = "File Name", url = "" }) => {
  const file_name = name.split(".")[0].split("-").join(" ");
  const file_extension = name.split(".")[1];

  return (
    <li className={styles.listItem}>
      <span className={styles.listContent}>
        <span className={styles.icon}>
          <DownloadIcon size={70} color="#bc0023" />
        </span>
        <span className={styles.file}>
          <span className={styles.fileTitle}>
            <span className={styles.fileName}>{`${file_name}`}</span>
            <span
              className={styles.fileType}
            >{`.${file_extension.toLowerCase()}`}</span>
          </span>
          <a href={url} download={name} className={styles.button}>
            <span className={styles.buttonText}>Download</span>
          </a>
        </span>
      </span>
    </li>
  );
};

export default DownloadCard;
