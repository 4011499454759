import React, { useReducer, useEffect, useState } from 'react';
import axios from 'axios';

//REDUCER TO HANDLE STATES
import formReducer from '../../reducers/FormReducer';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingComponent from '../Loading';

//INITIAL STATE
let initialContactFormState = {
    contactPerson: "",
    phoneNumber: "",
    emailAddress: "",
    countryState: "",
    promoCode: "",
    Message: "",
};

const ContactUsPageForm = ({ confirmationModalPopUp, resultStatus, formKind }) => {
    let [state, dispatch] = useReducer(formReducer, initialContactFormState);
    
    //HANDLES THE LOADING STATE
    let [loading, setLoading] = useState(false);

    useEffect(() => {

        const clearEventListener = () => {
            
        }

        return clearEventListener();
    }, []);


    const handleTextChange = (event) => {
        dispatch({
            type: "HANDLE CONTACT FORM INPUT",
            field: event.target.name,
            payload: event.target.value,
        });
    };

    const handleFormSubmit = (event) => {
        let data = state;
        //console.log(data);
        sendContactForm(data);
        setLoading(true);
        event.preventDefault();
    }

    const sendContactForm = async(data) => {
        try {
            let response = await axios.post('https://mailer.ciscientific.com/rapiddoorsqld/contact-form', data)
            const result = response.data;
            if (result.status === 'Message Recieved' || 'Message Sent') {
                console.log('Email Sent!');
                setLoading(false)
                resultStatus(true);
                formKind('calibration-contact-form');
                formReset();
                confirmationModalPopUp();
            }
        } catch (error) {
            console.log("Error | " + error.message);
            resultStatus(false);
            formKind('calibration-contact-form');
            confirmationModalPopUp();
        }
    }

     // RESET FORM FUNCTION
     const formReset = () =>{
        dispatch({type: "HANDLE FORM RESET"});

        let checkboxItems = document.querySelectorAll(".contact-us-page-checkbox");
        for (const items of checkboxItems) {
            items.checked = false;
        }
    }

    return (
        <section className='contact-us-page-form-main-container'>
            <div className="contact-us-page-form-content-container">
                <div className="contact-us-page-form-text-container">
                    <h2 className="contact-us-page-form-header-text">Let’s Answer your Queries</h2>
                    <p className="contact-us-page-form-text">
                        Please fill out the form below if you have feedback, questions, or concerns about our products and services.
                    </p>
                </div>
                <div className="contact-us-page-form-fields-container">
                    <form className='contact-us-page-form-fields' method='POST' onSubmit={handleFormSubmit}>
                        <div className="contact-us-page-form-fields-input-container">
                            <div className="contact-us-page-form-fields-input-left">
                                <TextField
                                    type="text"
                                    name="contactPerson"
                                    value={state.contactPerson}
                                    className='contact-us-page-form-field-item' 
                                    id="contact-person" 
                                    label="Contact Person" 
                                    variant="outlined" 
                                    margin="dense"
                                    sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                    required
                                    onChange={handleTextChange}
                                />

                                <TextField
                                    type='email'
                                    name="emailAddress"
                                    value={state.emailAddress}
                                    className='contact-us-page-form-field-item' 
                                    id="email-address" 
                                    label="Email Address" 
                                    variant="outlined" 
                                    margin="dense"
                                    sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                    required
                                    onChange={handleTextChange}
                                />
                            </div>

                            <div className="contact-us-page-form-fields-input-right">


                                <TextField
                                    type="number"
                                    name="phoneNumber"
                                    value={state.phoneNumber}
                                    className='contact-us-page-form-field-item' 
                                    id="contact-number" 
                                    label="Contact Number" 
                                    variant="outlined" 
                                    margin="dense"
                                    sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                    required
                                    onChange={handleTextChange}
                                />


                                <TextField
                                    type="text"
                                    name="countryState"
                                    value={state.countryState}
                                    className='contact-us-page-form-field-item' 
                                    id="country-state outlined-required" 
                                    label="Country/State" 
                                    variant="outlined" 
                                    margin="dense"
                                    sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                    required
                                    onChange={handleTextChange}
                                />
                            </div>
                        </div>

                        <div className="contact-us-page-promo-field-container">
                            <span>Inquire about our promos</span>


                            
                            <TextField
                                    type='text'
                                    name="promoCode"
                                    value={state.promoCode}
                                    className='contact-us-page-form-field-item'
                                    id="promo-code" 
                                    label="Enter promo code (optional)" 
                                    variant="outlined" 
                                    multiline
                                    margin="dense"
                                    sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                    onChange={handleTextChange}
                            />
                        </div>

                        <div className="contact-us-page-form-fields-input-container">
                            <TextField
                                type='text'
                                name="Message"
                                value={state.Message}
                                className='contact-us-page-form-field-item'
                                id="message message-box" 
                                label="Message" 
                                variant="outlined" 
                                multiline
                                rows={5}
                                margin="dense"
                                sx={{backgroundColor: '#FFFFFF', border: '1', width: '100%'}}
                                onChange={handleTextChange}
                            />
                        </div>

                        <div className="contact-us-page-form-fields-button-container">
                            {
                                loading ?
                                <LoadingComponent />
                                : <Button
                                className='contact-us-page-form-fields-button'
                                variant="contained"
                                color="primary"
                                size="large"
                                name='submit' 
                                value='submitRequest'
                                type='submit'
                            >
                                Submit
                            </Button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactUsPageForm;