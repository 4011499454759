import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const PrivacyPolicy = () => {
    return (
       <HelmetProvider>
            <div className='privacy-policy-container' id='privacy-policy'>
                {/**handles the META DATA of each page */}
                <Helmet>
                    <title>Privacy Policy | Rapid Doors Queensland</title>
                    <link rel="canonical" href="https://ciscalautoclavesaustralia.com.au/" />
                </Helmet>

                <div className='main-document'>
                <h1 className='document-title'>Privacy Policy</h1>
                <p className='document-text'>
                We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly high priority for the management of the My Sparky Mate. The use of the Internet pages of the My Sparky Mate is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary. If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.
                <br /><br />
                The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to the My Sparky Mate. By means of this data protection declaration, our enterprise would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.
                <br /><br />
                As the controller, the My Sparky Mate has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone.
                </p>
                <br />

                <ol type='A'>
                    <li className='list-title'>What is Personal Information and why do we collect it?</li>
                    <p className='document-text'>
                        Personal Information is information or an opinion that identifies an individual. 
                        Examples of Personal Information we collect include: names, addresses, email 
                        addresses, phone and facsimile numbers.
                        <br /><br />
                        This Personal Information is obtained in many ways including telephone and 
                        facsimile, by email, via our website www.ciscientific.com, from media and 
                        publications, from other publicly available sources, from cookies and from third 
                        parties. We don’t guarantee website links or policy of authorised third parties.
                        <br /><br />
                        We collect your Personal Information for the primary purpose of providing our 
                        services to you, providing information to our clients and marketing. We may also 
                        use your Personal Information for secondary purposes closely related to the 
                        primary purpose, in circumstances where you would reasonably expect such use or 
                        disclosure. You may unsubscribe from our mailing/marketing lists at any time by 
                        contacting us in writing.
                        <br /><br />
                        When we collect Personal Information we will, where appropriate and where 
                        possible, explain to you why we are collecting the information and how we 
                        plan to use it.
                    </p>

                    <li className='list-title'>Sensitive Information</li>
                    <p className='document-text'>
                        Sensitive information is defined in the Privacy Act to include information or 
                        opinion about such things as an individual’s racial or ethnic origin, political 
                        opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information.
                        <br /><br />
                        Sensitive information will be used by us only:
                        <br /><br />
                        <ul>
                            <li>For the primary purpose for which it was obtained</li>
                            <li>For a secondary purpose that is directly related to the primary purpose</li>
                            <li>With your consent; or where required or authorised by law.</li>
                        </ul>
                    </p>

                    <li className='list-title'>Third Parties</li>
                    <p className='document-text'>
                        Where reasonable and practicable to do so, we will collect your Personal 
                        Information only from you. However, in some circumstances we may be provided 
                        with information by third parties. In such a case we will take reasonable steps 
                        to ensure that you are made aware of the information provided to us by the third 
                        party.
                    </p>

                    <li className='list-title'>Disclosure of Personal Information</li>
                    <p className='document-text'>
                        Your Personal Information may be disclosed in several circumstances 
                        including the following:
                        <br />
                        <ul>
                            <li>Third parties where you consent to the use or disclosure; and</li>
                            <li>Where required or authorised by law.</li>
                        </ul>

                    </p>

                    <li className='list-title'>Security of Personal Information</li>
                    <p className='document-text'>
                        Your Personal Information is stored in a manner that reasonably protects it from 
                        misuse and loss and from unauthorized access, modification or disclosure.
                        <br /><br />
                        When your Personal Information is no longer needed for the purpose for which 
                        it was obtained, we will take reasonable steps to destroy or permanently 
                        de-identify your Personal Information. However, most of the Personal Information 
                        is or will be stored in client files which will be kept by us for a minimum of 
                        7 years.
                    </p>

                    <li className='list-title'>Access to your Personal Information</li>
                    <p className='document-text'>
                        You may access the Personal Information we hold about you and to update and/or correct it, 
                        subject to certain exceptions. If you wish to access your Personal Information, please contact us
                        in writing.
                        <br /><br />
                        <b>CI Scientific Pty Ltd</b> will not charge any fee for your access request but may charge an 
                        administrative fee for providing a copy of your Personal Information.
                        <br /><br />
                        To protect your Personal Information, we may require identification from you before releasing 
                        the requested information.
                    </p>

                    <li className='list-title'>Maintaining the Quality of your Personal Information</li>
                    <p className='document-text'>
                        It is an important to us that your Personal Information is up to date. 
                        We will take reasonable steps to make sure that your Personal Information is 
                        accurate, complete and up-to-date. If you find that the information we have is 
                        not up to date or is inaccurate, please advise us as soon as practicable so we 
                        can update our records and ensure we can continue to provide quality services to 
                        you.
                    </p>

                    <li className='list-title'>Policy Updates</li>
                    <p className='document-text'>
                        This Policy may change from time to time and is available on our website.
                    </p>
                </ol>

                </div>
            </div>
       </HelmetProvider>
    );
};

export default PrivacyPolicy;