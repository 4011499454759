import { useEffect, useState } from "react";

const useFetch = ({ method, url }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (method === "GET") {
      const getData = async () => {
        try {
          const response = await fetch(url);
          const result = await response.json();
          setData(result);
        } catch (error) {
          setError(error);
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        }
      };
      getData();
    }
  }, [url, method]);

  return { data, loading, error };
};

export default useFetch;
