import React, { useEffect, useState } from "react";
// import useResizeObserver from "@react-hook/resize-observer";
// import { usePrevious } from "react-use";
// import { NavLink } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

//BOOTSTRAP IMPORTS
//import Container from 'react-bootstrap/Container';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

//MATERIAL UI IMPORTS
import Button from "@mui/material/Button";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import Tooltip from "@mui/material/Tooltip";

//IMAGES IMPORTS
import navbarToggler from "../images/icons/navbar-toggler.svg";
import RDQ from "../images/logos/rapid-doors-qld-logo.png";

function NavigationBar() {
  //handle rendered component when app is minimized
  const isMobile = useMediaQuery({ maxWidth: 767 }); // 767px is the Bootstrap breakpoint for mobile devices
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  return (
    <>
      <Navbar className={` navbar fixed-top minimized`}>
        <div className="navbar-content">
          <Navbar.Brand href="/" className="navbar-brand-container">
            <img
              src={RDQ}
              className="d-inline-block align-top ciscal-autoclave-australia-logo"
              alt="msms-rapid-doors-queensland-logo"
            />
          </Navbar.Brand>

          {isMobile ? (
            <>
              <Button
                variant="primary"
                onClick={() => setShowOffcanvas(true)}
                className="navigation-bar-nav-button-container"
                aria-label="hamburger-button"
              >
                <img
                  src={navbarToggler}
                  className="navigation-bar-hamburger-icon"
                  alt=""
                />
              </Button>

              <Offcanvas
                show={showOffcanvas}
                onHide={() => setShowOffcanvas(false)}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  <Nav className="mx-auto off-canvas-items-list">
                    <Nav.Link href="/" id="off-canvas-item">
                      HOME
                    </Nav.Link>
                    <Nav.Link href="/products" id="off-canvas-item">
                      RAPID DOORS
                    </Nav.Link>
                    <Nav.Link href="/contact" id="off-canvas-item">
                      CONTACT
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Offcanvas>
            </>
          ) : (
            <>
              <Nav className="mx-auto navigation-bar-items-list">
                <Nav.Link href="/" className="navigation-bar-item">
                  Home
                </Nav.Link>
                <Nav.Link href="/products" className="navigation-bar-item">
                  Rapid Doors
                </Nav.Link>
                <Nav.Link href="/about-us" className="navigation-bar-item">
                  About Us
                </Nav.Link>
                <Nav.Link href="/contact" className="navigation-bar-item">
                  Contact
                </Nav.Link>
              </Nav>

              <Nav className="navigation-bar-items-list-right">
                <Tooltip title="Office Hours" placement="bottom" arrow>
                  <p className="navigation-bar-item-office-hours">
                    Monday - Friday : 7am - 3pm
                  </p>
                </Tooltip>

                <Tooltip title="Call" placement="right" arrow>
                  <Button
                    variant="contained"
                    href="tel:1300 469 772"
                    size="small"
                    className="navigation-bar-nav-button"
                  >
                    <PhoneOutlinedIcon className="navigation-bar-phone-icon" />
                    1300-469-772
                  </Button>
                </Tooltip>
              </Nav>
            </>
          )}
        </div>
      </Navbar>
    </>
  );
}

export default NavigationBar;
