import React from "react";
import PropTypes from "prop-types";
import styles from "./resources/css/SingleProductComponent.module.scss";

const SingleProductComponent = ({
  image,
  title,
  subtitle,
  buttonText,
  productLink,
}) => {
  return (
    <div className={styles.productCard}>
      <div className={styles.imageWrapper}>
        <img src={image} alt={title} className={styles.productImage} />
      </div>
      <div className={styles.cardContent}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.subtitle}>{subtitle}</p>
        <a href={productLink}>
          <button className={styles.viewButton}>{buttonText}</button>
        </a>
      </div>
    </div>
  );
};

SingleProductComponent.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default SingleProductComponent;
