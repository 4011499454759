import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import BannerCarousel from "../components/homepage/BannerCarousel";
import Introduction from "../components/homepage/Introduction";
// import FeaturesCards from '../components/homepage/FeaturesCards';
// import Industries from '../components/homepage/Industries';
// import BestSellingProducts from '../components/homepage/BestSellingProducts';
// import AboutUsSnippet from '../components/homepage/AboutUsSnippet';
// import WhatCustomersSay from '../components/homepage/WhatCustomersSay';
import ContactUsSnippet from "../components/homepage/ContactUsSnippet";
import BrowseByIndustryCards from "../components/homepage/BrowseByIndustryCards";
import CategoryListSection from "../components/products-main-page/category-list-section/CategoryListSection";

function Homepage(props) {
  const {
    CalibrationModalPopUp,
    confirmationModalPopUp,
    resultStatus,
    formKind,
  } = props;

  return (
    <HelmetProvider>
      <div>
        {/**handles the META DATA of each page */}
        <Helmet>
          <title>
            Rapid Doors Queensland | Installation, Repair, and Maintenance of
            Rapid Doors
          </title>
          <meta
            name="description"
            content="Rapid Doors Queensland is your one-stop shop for rapid doors installation, repair, and maintenance. We offer a wide range of services for a variety of rapid doors, including high-speed doors, roll-up doors, and industrial doors. We are experienced, quick, and cost-effective, and we are committed to keeping your business moving."
          />
          <meta
            name="keywords"
            content="
            rapid doors queensland, 
            rapid doors installation, 
            rapid doors repair, 
            rapid doors maintenance, 
            high-speed doors, 
            roll-up doors, 
            industrial doors, 
            dmf doors, 
            remax doors, 
            safetech doors, 
            efaflex doors, 
            ansa doors, 
            mirage doors, 
            assa abloy doors
            Rapid doors, 
            high-speed doors, 
            roll-up doors, 
            industrial doors, 
            installation, 
            repair, 
            maintenance, 
            preventive maintenance, 
            Queensland, 
            idustrial doors australia, 
            rapid doors australia, 
            meadowbrook australia door services,
            meadowbrook queensland services,
            rapid roller doors, 
            rapid doors, 
            rapid roller door,
            rapid doors australia,
            industrial doors services queensland
            broken rapid doors, 
            industrial door businesses australia"
          />
          <link
            rel="canonical"
            href="https://rapiddoorsqueensland.mysparkymate.com.au/"
          />
        </Helmet>

        <BannerCarousel CalibrationModalPopUp={CalibrationModalPopUp} />
        <Introduction />
        <CategoryListSection />
        <ContactUsSnippet
          confirmationModalPopUp={confirmationModalPopUp}
          resultStatus={resultStatus}
          formKind={formKind}
        />
      </div>
    </HelmetProvider>
  );
}

export default Homepage;
