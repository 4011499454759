import { useState, useEffect, useRef } from "react";
import styles from "./resources/styles/ImageSlider.module.scss";
import ArrowLeftIcon from "./resources/icons/ArrowLeftIcon";
import ArrowRightIcon from "./resources/icons/ArrowRightIcon";
import { IconButton } from "@mui/material";

import place_holder_image from "../../../../../../images/placeholder-image/placeholder-image.png";

const ImageSlider = ({ product_images, loading, error }) => {
  const [mainImage, setMainImage] = useState("");
  const imagesRef = useRef(null);

  if (error) {
    console.log("Error encountered");
  }

  const handleScrollLeft = () => {
    imagesRef.current.scrollBy({
      left: -200, // Set the amount to scroll by
      behavior: "smooth", // Use smooth scrolling
    });
  };

  const handleScrollRight = () => {
    imagesRef.current.scrollBy({
      left: 200, // Set the amount to scroll by
      behavior: "smooth", // Use smooth scrolling
    });
  };

  useEffect(() => {
    if (!loading && product_images !== null) {
      let main_image = `${process.env.REACT_APP_CMS_URL}/${product_images[0].url}`;
      setMainImage(main_image);
    } else {
      let main_image = place_holder_image;
      setMainImage(main_image);
    }
  }, [loading, product_images]);

  // renders the image items from CMS.
  const image_items = product_images?.map(({ url, name }, index) => {
    return (
      <div className={styles.imageItemContainer} key={index}>
        <img
          src={`${process.env.REACT_APP_CMS_URL}/${url}`}
          alt={name}
          className={styles.itemImage}
          onClick={() =>
            setMainImage(`${process.env.REACT_APP_CMS_URL}/${url}`)
          }
        />
      </div>
    );
  });

  // renders the whole ImageSlider component.
  return (
    <section className={styles.imageContainer}>
      <div className={styles.imageContent}>
        <div className={styles.mainImage}>
          <img
            src={mainImage || place_holder_image}
            alt={product_images === null ? "" : `${product_images[0].name}`}
            className={styles.image}
          />
        </div>
        <div className={styles.imageSlides}>
          <IconButton
            aria-label="slide left"
            size="large"
            onClick={handleScrollLeft}
          >
            <ArrowLeftIcon size={50} />
          </IconButton>
          <div className={styles.imageSlidesImages} ref={imagesRef}>
            {image_items}
          </div>
          <IconButton
            aria-label="slide right"
            size="large"
            onClick={handleScrollRight}
          >
            <ArrowRightIcon size={50} />
          </IconButton>
        </div>
      </div>
    </section>
  );
};

export default ImageSlider;
