import React from 'react'

function AssaAbloySpecification() {
  return (
    <section className='specification-main-section'>
      <p>No Data Available as of the moment. </p>
    </section>
  )
}

export default AssaAbloySpecification