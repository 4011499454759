import { React, useState, useEffect, useRef } from 'react';

// MUI
import Button from '@mui/material/Button';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

//BOOTSTRAP
import Modal from 'react-bootstrap/Modal';


//IMAGES
import redGradientBackground from '../../images/body-images/red-gradient-background-3.png';
import leftArrow from '../../images/icons/arrow-icon-left.svg';
import rightArrow from '../../images/icons/arrow-icon-right.svg';
import Safetech1 from '../../images/products/safetech-1.jpg';
import Safetech2 from '../../images/products/safetech-2.jpg';
import Safetech3 from '../../images/products/safetech-3.jpg';
import Safetech4 from '../../images/products/safetech-4.jpg';
import Safetech5 from '../../images/products/safetech-5.jpg';



function SingleProductPageSafetechBanner({ CalibrationModalPopUp }) {


  const productImages = [ Safetech3, Safetech1, Safetech2, Safetech4, Safetech5 ]; // to handle the product mages array
    const [bottomPadding, setBottomPadding] = useState(0); //to set the bottom padding for the get quote btn (mobile view)
    const [displayImage, setDisplayImage] = useState([Safetech3]); //handles the main image displayed. 


    const [show, setShow] = useState(false); //handle image fullscreen modal
    const handleClose = () => setShow(false); // to close modal
    const handleShow = () => setShow(true); // to open modal




    // handle the scroll event in image gallery using the arrows
    const imagesRef = useRef(null);

    const handleScrollLeft = () => {

        imagesRef.current.scrollBy({
            left: -200, // Set the amount to scroll by
            behavior: 'smooth'  // Use smooth scrolling
        });
    };

    const handleScrollRight = () => {
        imagesRef.current.scrollBy({
            left: 200, // Set the amount to scroll by
            behavior: 'smooth'  // Use smooth scrolling
        });
    };

    useEffect(() => {
        // Get the height of the bottom toolbar and URL bar
        const toolbarHeight = window.innerHeight - document.documentElement.clientHeight;
        setBottomPadding(toolbarHeight);
        
        // Listen for the resize and scroll events and update the padding
        const handleResize = () => {
        const newHeight = window.innerHeight - document.documentElement.clientHeight;
        setBottomPadding(newHeight);
        };

        const handleScroll = () => {
        const newHeight = window.innerHeight - document.documentElement.clientHeight + window.pageYOffset;
        setBottomPadding(newHeight);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);
        
        // Clean up the event listeners
        return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
        }

    }, []);



  return (
    
    <>
    <div className='singe-product-page-container'>
        <div className='single-product-page-blue-background'><img src={redGradientBackground}  alt='blue-background' /></div>

        <div className='single-product-page-content'>
            <div className='single-product-page-details'>
                
                <p>Unlocking Excellence, Securing Tomorrow - Your Safetech Doors Partner with Rapid Doors Queensland.</p>
                <h2>SAFETECH DOORS</h2>
                <p>
                    Rapid Doors Queensland stands at the forefront of excellence as the premier service provider for Safetech Doors in Australia, with a proven track record of delivering top-tier solutions for all your door needs. Our direct and professional approach ensures that your Safetech Doors are always in optimal condition, guaranteeing safety, functionality, and peace of mind.
                </p>
                <div className='single-product-page-image-gallery'>
                    <div className='single-product-page-image-gallery-arrow'><img src={leftArrow} alt="" onClick={handleScrollLeft}/></div>
                    

                        <div className='single-product-page-images-container' ref={imagesRef}>
                            {productImages.map((image, i) => (
                            <div className='single-product-page-image-div' key={`image-${i}`}>
                                <img className='single-product-page-image' src={image} alt="safetech doors" onClick={e => setDisplayImage(image)}/>
                            </div>
                            ))}
                        </div>


                    <div className='single-product-page-image-gallery-arrow'><img src={rightArrow} alt=" " onClick={handleScrollRight} /></div>
                </div>

                <div className='single-product-page-get-a-quote-div' style={{ paddingBottom: `${bottomPadding}px` }}><Button onClick={CalibrationModalPopUp}  variant="contained" className='main-body-button' id='single-product-page-get-a-quote-btn' value='view-all' >GET A QUOTE</Button></div>

            </div>
            <div className='single-product-page-main-image-div'>
                <div className='single-product-page-main-image'>
                    <img src={displayImage} key={`display-image`} alt='' />

                    <div className='single-product-page-image-fullscreen-icon-div'>
                    <OpenInFullIcon className='single-product-page-image-fullscreen-icon' onClick={handleShow}/>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className='single-product-page-fullscreen-modal-div'>
    <Modal 
        show={show} 
        onHide={handleClose} 
        centered 
        animation={false} 
        size='lg'
        className='single-product-page-fullscreen-modal'
        >

        <Modal.Header closeButton/>
        <Modal.Body className='single-product-page-fullscreen-modal-body'>

        <div id='single-product-page-fullscreen-modal-img-div' style={{display: "flex", justifyContent: "center"  }}><img src={displayImage} className='single-product-page-fullscreen-modal-img img-fluid' alt='safetech doors fullscreen' /></div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            Close
            </Button>
        </Modal.Footer> */}
        </Modal>
    </div>
    
    </>
    
  )
}

export default SingleProductPageSafetechBanner