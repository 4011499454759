import React from "react";
import styles from "./resources/styles/LoadingUI.module.scss";

const LoadingUI = () => {
  return (
    <main className={styles.mainContainer}>
      <div className={styles.loader}>
        <div className={styles.box1}></div>
        <div className={styles.box2}></div>
        <div className={styles.box3}></div>
      </div>
    </main>
  );
};

export default LoadingUI;
