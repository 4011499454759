import React from "react";
import LoadingUI from "../components/loading-ui/LoadingUI";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import ProductsMainPageBanner from "../components/products-main-page/products-main-page-banner/ProductsMainPageBanner";
import ProductListByCategory from "../components/products-main-page/products-list-by-category-section/ProductsListSection";

import ProductsSecondTitleBanner from "../components/products-main-page/products-second-title-banner/ProductsSecondTitleBanner";

import ErrorBoundary from "../components/error-boundary/ErrorBoundary";
import ErrorUI from "../components/error-boundary/page-wide-error-boundary/ErrorUI";

const CategoryProductPage = () => {
  const { slug } = useParams();

  const { data, loading, error } = useFetch({
    method: "GET",
    url: `https://rdqcms.ciscalautomation.com/api/categories/${slug}`,
  });

  const paragraphs = [
    "In the dynamic rhythm of the modern era, velocity and precision are the keystones of triumph. This is particularly true for sectors like food processing, logistics, and manufacturing, where time is of the essence. High-speed doors emerge as a pivotal element for enterprises demanding swift and regular access to distinct zones within their premises, be it for streamlined loading procedures, stringent temperature and cleanliness standards, or the sheer enhancement of workflow efficiency.",
    "Ulti Group’s high-speed doors are engineered to cater to the specialized demands of these fast-moving sectors, ensuring peak efficiency and fortified safety to safeguard your workforce, merchandise, and financial health. In pursuit of operational excellence and a competitive edge, consider Ulti Group’s high-speed doors as your solution. Reach out to us today to elevate your business operations.",
  ];

  if (loading) {
    return <LoadingUI />;
  }
  return (
    <ErrorBoundary fallback={<ErrorUI />}>
      <div>
        <ProductsMainPageBanner category_data={data} error={error} />
        <ProductsSecondTitleBanner
          subHeading="Your Partners in Accelerating Success"
          heading="Embrace Velocity"
          paragraphs={paragraphs}
        />
        <ProductListByCategory />
      </div>
    </ErrorBoundary>
  );
};

export default CategoryProductPage;
