import React from 'react'

//images
// import rapidDoors from '../../images/body-images/rapid-doors-intro.jpeg';
import rapidDoors from '../../images/body-images/rapid-doors-intro-2.jpg';

function Introduction() {


  return (
    <div className='homepage-introduction-container'>

        <div className='homepage-introduction-content'>

            <h2>Your one-stop shop for Rapid Doors Installation, Repair, and Maintenance</h2>

            <div className='homepage-introduction-cards'>
                <div className='homepage-introduction-image-div'>
                    <img src={rapidDoors} className='homepage-introduction-image' alt=" " />
                </div>
    
                <div className='homepage-introduction-text-div'>

                    <p className='homepage-introduction-text-list-title'>We provide top-notch service and maintenance for a wide range of rapid doors, including:</p>
                    <ul className='homepage-introduction-text-list'>
    
                        <li>High-speed doors</li>
                        <li>Roll-up doors</li>
                        <li>Industrial doors</li>
    
                    </ul>

                    <p className='homepage-introduction-text-list-title'>We offer the following services:</p>

                    <ul className='homepage-introduction-text-list'>

                        <li>Installation</li>
                        <li>Preventive maintenance</li>
                        <li>Repair</li>
                        <li>Cleaning</li>
                        <li>Lubrication</li>
                    </ul>
                </div>
            </div>



        </div>

    </div>
  )
}

export default Introduction