import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

// IMAGES
import ourFounding from '../../src/images/body-images/about-us-page-image-our-founding.jpg';
import expansionGrowth from '../../src/images/body-images/about-us-page-image-growth-and-expansion.jpg'
import autoclaveAustralia from '../../src/images/body-images/about-us-page-image-autoclave-australia.webp';
import expandingReach from '../../src/images/body-images/about-us-page-image-expanding-our-reach.webp';

function AboutUsPage() {
  return (
    <HelmetProvider>
        <div className='about-us-page-container'>
            {/**handles the META DATA of each page */}
            <Helmet>
                <title>About Us | Rapid Doors Queensland - Your Trusted Partner for Industrial Doors</title>
                <meta name="description" content="Rapid Doors Queensland is your trusted partner for all your industrial door needs. We offer a comprehensive range of services, from installation to maintenance, inspection, and repair, all designed to keep your business running smoothly and efficiently. Our team of experienced professionals has years of experience in the industry, and we are dedicated to providing our clients with the highest level of service and quality workmanship."/>
                <meta name="keywords" content="industrial doors, automatic doors, door installation, door repair, door maintenance, industrial door services, rapid doors queensland, my sparky mate, dmf doors"/>
                <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au/" />
            </Helmet>

            <div className='about-us-page-content'>
                <div className='about-us-page-title-div'>
                    <h2>About Us</h2>
                    <p>
                        Welcome to Rapid Doors Queensland, your trusted partner for all your industrial door needs. We are proud to offer a comprehensive range of services, from installation to maintenance, inspection, and repair, all designed to keep your business running smoothly and efficiently.
                        <br/><br/>
                        At Rapid Doors Queensland, we pride ourselves on our commitment to excellence. Our team of experienced professionals has years of experience in the industry, and we are dedicated to providing our clients with the highest level of service and quality workmanship. We understand that your time is valuable, and we are committed to delivering fast, reliable, and cost-effective solutions that meet your unique needs.
                    </p>
                </div>

                <div className='about-us-page-items'>
                    <div className='about-us-page-item'>
                    <div className='about-us-page-details'>
                        <div className='about-us-page-details-image-div'>
                        <img src={ourFounding} alt="ci-scientific-our-founding" />
                        </div>
                        <div className='about-us-details-text'>
                        <h3>Our Founding</h3>
                        <p>
                            <strong>2012:</strong> My Sparky Mate is founded, specializing in electrical services including Aircon Repair, Maintenance, installation, and other services generally in the electrical industry.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='about-us-page-items'>
                    <div className='about-us-page-item'>
                    <div className='about-us-page-details' id='about-us-page-growth-and-expansion'>
                        <div className='about-us-details-text'>
                        <h3>Growth & Expansion</h3>
                        <p>
                            <strong>2012-2022: </strong>My Sparky Mate expands its services to include industrial door installation, inspection, repair, and maintenance, becoming a trusted provider in the industry.
                        </p>
                        </div>
                        <div className='about-us-page-details-image-div'>
                        <img src={expansionGrowth} alt="ci-scientific-growth-and-expansion" />
                        </div>
                    </div>
                    </div>
                </div>

                <div className='about-us-page-items'>
                    <div className='about-us-page-item'>
                    <div className='about-us-page-details'>
                        <div className='about-us-page-details-image-div'>
                        <img src={autoclaveAustralia} alt="ci-scientific-autoclave-australia" />
                        </div>
                        <div className='about-us-details-text'>
                        <h3>Rapid Doors Queensland</h3>
                        <p>
                            <strong>March 2022: </strong>My Sparky Mate established Rapid Doors Queensland, as a part of the company specializing in industrial door services, as part of its expansion plans.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='about-us-page-items'>
                    <div className='about-us-page-item'>
                    <div className='about-us-page-details'
                    id='about-us-page-growth-and-expansion'>
                        <div className='about-us-details-text'>
                        <h3>Expanding Our Reach</h3>
                        <p>
                            <strong>March 2022-Present: </strong>Rapid Doors Queensland operates as a department of My Sparky Mate, with a focus on providing high-quality industrial door services to customers in Logan, Brisbane City, Gold Coast, and surrounding areas.
                            <br/><br/>
                            Rapid Doors Queensland launches its new website, featuring a wide range of industrial doors, including DMF Doors, and offering installation, inspection, repair, and maintenance services to customers across Queensland.
                        </p>
                        </div>
                        <div className='about-us-page-details-image-div'>
                        <img src={expandingReach} alt="ci-scientific-expanding-our-reach" />
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </HelmetProvider>
  )
}

export default AboutUsPage