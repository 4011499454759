import React from 'react';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


//COMPONENTS
import SingleProductPageAssaAbloyBanner from '../components/single-product-page/SingleProductPageAssaAbloyBanner';
import SingleProductPageAssaAbloyDetails from '../components/single-product-page/SingleProductPageAssaAbloyDetails';


function SingleProductPageAssaAbloy(prop) {

    //passing prop from app.js
    const { CalibrationModalPopUp } = prop;


    //to make sure window will reload at the top coming from other pages
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    //usage of the checking of freshchat callback function
    useEffect(() => {
  
      waitFreshchatWidget(() => {
  
        //console.log('Widget is now loaded. Run your function.');
        window.fcWidget.hide();
      });
  
    });
  
   
    //callback function to wait for freshchat widget to load
    // Widget not loaded, wait and check again
    const waitFreshchatWidget = (callback) => {
  
      if (typeof window.fcWidget !== 'undefined' && window.fcWidget.isLoaded()) {
        //console.log('Widget already loaded');
        callback();
    }
    else {
        
      //rerun the function again to recheck
      //console.log('Widget not yet loaded, check again');
      setTimeout(() => {
        waitFreshchatWidget( callback);}, 50);
    }
  }


  return (
    

    <HelmetProvider>
    <div>
      {/**handles the META DATA of each page */}
      <Helmet>
          <title>ASSA ABLOY Doors by Rapid Doors Queensland - The most complete and comprehensive range of doors available to the construction sector.</title>
          <meta name="description" content="Rapid Doors Queensland is the premier provider of ASSA ABLOY Doors services in Queensland. We offer a wide range of services for ASSA ABLOY Doors, including inspection, repair, and maintenance. Our team of experts has an unmatched understanding of ASSA ABLOY Doors' intricate systems, ensuring flawless installations, timely repairs, and meticulous maintenance that extends the lifespan and maximizes the efficiency of these state-of-the-art industrial doors."/>
          <meta name="keywords" content="assa abloy doors, rapid doors queensland, assa abloy doors repair, assa abloy doors maintenance, assa abloy doors inspection, fire doors, smoke-protection doors, security doors, soundproof doors, bulletproof doors, fire curtains, warehouses, factories, manufacturing plants, commercial buildings, healthcare facilities, educational institutions"/>
          <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au/" />
      </Helmet>

      <SingleProductPageAssaAbloyBanner CalibrationModalPopUp = { CalibrationModalPopUp } />

      <div className='single-product-page-details-container'>
      <div className='single-product-page-details-content'>
      <SingleProductPageAssaAbloyDetails  />
      </div>
      </div>
    </div>
  </HelmetProvider>


  )
}

export default SingleProductPageAssaAbloy