import React from 'react';
// import { Link } from 'react-router-dom';

//BOOTSTRAP IMPORTS
import Carousel from 'react-bootstrap/Carousel';

//MATERIAL UI IMPORTS
import Button from '@mui/material/Button';

// IMAGES
import rapiddoorsbanner from '../../images/banners/rapid-doors-banner.jpg';
import rapiddoorsbanner2 from '../../images/banners/rdq-homepage-slider-benner.webp';


function BannerCarousel(props) {

  const { CalibrationModalPopUp } = props


  return (
    <div className='homepage-banner-carousel-container'>

      <Carousel className='homepage-banner-carousel'>

        <Carousel.Item className='homepage-banner-carousel-item'>
        <div className='homepage-banner-carousel-details-div'>
          <div className='homepage-banner-carousel-details'>
            <div className='homepage-banner-carousel-text-container'>
              <h1>Your Trusted Partner on <span>Industrial Doors Services. </span></h1>
              <p>We offer a  wide variety of industrial doors services, with trained and certified professional to make sure that you get the quality service that you deserve.</p>
            </div>
    
            <div className='homepage-banner-carousel-button-container'>
              <div>
                <Button  to='/contact-us' variant="contained" className='homepage-banner-button' id='homepage-banner-get-a-quote-button' value='get-a-quote' onClick={CalibrationModalPopUp}>Get A Quote</Button>
              </div>
              <div>
                <Button href='/brands' variant="outlined" className='homepage-banner-button' id='explore-more-button' value='explore-more' >Explore More</Button>
                </div>
            </div>
          </div >
  
          <div className='homepage-banner-carousel-image-div'>
            <img src={rapiddoorsbanner2} alt=''/>
          </div>
        </div>
        </Carousel.Item>

        <Carousel.Item className='homepage-banner-carousel-item'>
        <div className='homepage-banner-carousel-details-div'>
          <div className='homepage-banner-carousel-details'>
            <div className='homepage-banner-carousel-text-container'>
              <h1>We Keep Your <span> Business Moving</span></h1>
              <p>Expert Rapid Door Installation, Repairs, and Maintenance for Your Business.</p>
            </div>
    
            <div className='homepage-banner-carousel-button-container'>
              <div>
                <Button  to='/contact-us' variant="contained" className='homepage-banner-button' id='homepage-banner-get-a-quote-button' value='get-a-quote' onClick={CalibrationModalPopUp}>Get A Quote</Button>
              </div>
              <div>
                <Button href='/brands' variant="outlined" className='homepage-banner-button' id='explore-more-button' value='explore-more' >Explore More</Button>
                </div>
            </div>
          </div >
  
          <div className='homepage-banner-carousel-image-div'>
            <img src={rapiddoorsbanner} alt=''/>
          </div>
        </div>
        </Carousel.Item>
    </Carousel>



    </div>
  )
}

export default BannerCarousel