import React from "react";
import styles from "./resources/css/SingleCategoryComponent.module.scss";

const SingleCategoryComponent = ({ image, title, categoryLink }) => {
  return (
    <a
      href={categoryLink}
      className={styles.singleCategoryComponent}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles.overlay}>
        <span className={styles.categoryTitle}>{title}</span>
      </div>
    </a>
  );
};

export default SingleCategoryComponent;
