import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import ContactUsPageBanner from '../components/contact-us-page/ContactUsPageBanner';
import ContactUsPageForm from '../components/contact-us-page/ContactUsPageForm';


const ContactUsPage = ({confirmationModalPopUp, resultStatus, formKind}) => {
    return (
        <HelmetProvider>
            <div className='contact-us-page-main-container'>
                {/**handles the META DATA of each page */}
                <Helmet>
                    <title>Rapid Doors Queensland | Contact Us for Expert Rapid Door Installation, Maintenance, and Repair</title>
                    <meta name="description" content="Rapid Doors Queensland is your one-stop shop for all your rapid door needs. We offer expert installation, maintenance, and repair services for rapid doors of all types. Contact us today to learn more or to schedule a consultation."/>
                    <meta name="keywords" content="rapid doors, rapid door installation, rapid door maintenance, rapid door repair, rapid doors queensland, rapid door service, rapid door company"/>
                    <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au/" />
                </Helmet>

                <div className="contact-us-page-content-container">
                    <ContactUsPageBanner />
                    <ContactUsPageForm 
                        confirmationModalPopUp = { confirmationModalPopUp }
                        resultStatus = { resultStatus }
                        formKind = { formKind }
                    />
                </div>
            </div>
        </HelmetProvider>
    );
};

export default ContactUsPage;