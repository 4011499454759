import React from 'react';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


//COMPONENTS
import SingleProductPageSafetechBanner from '../components/single-product-page/SingleProductPageSafetechBanner';
import SingleProductPageSafetechDetails from '../components/single-product-page/SingleProductPageSafetechDetails';



function SingleProductPageSafetech(prop) {

    //passing prop from app.js
    const { CalibrationModalPopUp } = prop;


    //to make sure window will reload at the top coming from other pages
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    //usage of the checking of freshchat callback function
    useEffect(() => {
  
      waitFreshchatWidget(() => {
  
        //console.log('Widget is now loaded. Run your function.');
        window.fcWidget.hide();
      });
  
    });
  
   
    //callback function to wait for freshchat widget to load
    // Widget not loaded, wait and check again
    const waitFreshchatWidget = (callback) => {
  
      if (typeof window.fcWidget !== 'undefined' && window.fcWidget.isLoaded()) {
        //console.log('Widget already loaded');
        callback();
    }
    else {
        
      //rerun the function again to recheck
      //console.log('Widget not yet loaded, check again');
      setTimeout(() => {
        waitFreshchatWidget( callback);}, 50);
    }
  }


  return (
    

    <HelmetProvider>
    <div>
      {/**handles the META DATA of each page */}
      <Helmet>
          <title>Rapid Doors Queensland | Safetech Doors - Security & Access Control Solutions</title>
          <meta name="description" content="Rapid Doors Queensland is the premier service provider for Safetech Doors in Australia, offering a wide range of services to keep your doors in top condition. We provide inspection, repair, and maintenance services for Roller Doors, Sectional Overhead Doors, and Internal Doors & Facility Access Packages. Our team of experts is dedicated to safeguarding your investments while ensuring seamless access and unparalleled protection. Contact us today to learn more about our Safetech Doors services."/>
          <meta 
          name="keywords" 
          content="Safetech Doors,
          Industrial Doors,
          Security Doors,
          Access Control Doors,
          Roller Doors,
          Sectional Overhead Doors,
          Internal Doors,
          Facility Access Packages,
          Inspection,
          Repair,
          Maintenance,
          Rapid Doors Queensland,
          Australia"/>
          <link rel="canonical" href="https://rapidoorsqueensland.mysparkymate.com.au/" />
      </Helmet>

      <SingleProductPageSafetechBanner CalibrationModalPopUp = { CalibrationModalPopUp } />

      <div className='single-product-page-details-container'>
      <div className='single-product-page-details-content'>
      <SingleProductPageSafetechDetails  />
      </div>
      </div>
    </div>
  </HelmetProvider>


  )
}

export default SingleProductPageSafetech