import React from 'react';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


//COMPONENTS
import SingleProductPageMirageBanner from '../components/single-product-page/SingleProductPageMirageBanner';
import SingleProductPageMirageDetails from '../components/single-product-page/SingleProductPageMirageDetails';

function SingleProductPageMirage(prop) {

  //passing prop from app.js
  const { CalibrationModalPopUp } = prop;


  //to make sure window will reload at the top coming from other pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //usage of the checking of freshchat callback function
  useEffect(() => {

    waitFreshchatWidget(() => {

      //console.log('Widget is now loaded. Run your function.');
      window.fcWidget.hide();
    });

  });

 
  //callback function to wait for freshchat widget to load
  // Widget not loaded, wait and check again
  const waitFreshchatWidget = (callback) => {

    if (typeof window.fcWidget !== 'undefined' && window.fcWidget.isLoaded()) {
      //console.log('Widget already loaded');
      callback();
  }
  else {
      
    //rerun the function again to recheck
    //console.log('Widget not yet loaded, check again');
    setTimeout(() => {
      waitFreshchatWidget( callback);}, 50);
  }
}


  return (
    
    <HelmetProvider>
    <div>
      {/**handles the META DATA of each page */}
      <Helmet>
          <title>Mirage Doors by Rapid Doors Queensland | Installation, Maintenance, and Repair</title>
          <meta name="description" content="Rapid Doors Queensland is the premier service provider for Mirage Doors in Queensland. We offer a wide range of services for Mirage Doors, including inspection, repair, and maintenance. Our team of experts has an unmatched understanding of Mirage Doors' intricate systems, ensuring flawless installations, timely repairs, and meticulous maintenance that extends the lifespan and maximizes the efficiency of these state-of-the-art industrial doors."/>
          <meta name="keywords" content=" mirage doors, rapid doors queensland, mirage doors installation, mirage doors maintenance, mirage doors repair, aluminium roller shutters, roller grilles, steel roller shutters, aluminium folding closures, aluminium stacking doors, counterweight doors, truck shutters, warehouses, distribution centers, manufacturing plants, retail spaces, commercial buildings"/>
          <link rel="canonical" href="https://rapiddoorsqueensland.mysparkymate.com.au" />
      </Helmet>

      <SingleProductPageMirageBanner CalibrationModalPopUp = { CalibrationModalPopUp } />

      <div className='single-product-page-details-container'>
      <div className='single-product-page-details-content'>
      <SingleProductPageMirageDetails  />
      </div>
      </div>
    </div>
  </HelmetProvider>


  )
}

export default SingleProductPageMirage