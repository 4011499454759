import React from "react";
import styles from "./resources/css/SingleBrandComponent.module.scss";

const SingleBrandComponent = ({ image, brandLink, title }) => {
  return (
    <a
      href={brandLink}
      className={styles.singleBrandComponent}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles.overlay}>
        <span className={styles.brandTitle}>{title}</span>
      </div>
    </a>
  );
};

export default SingleBrandComponent;
