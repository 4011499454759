import { createContext, useContext, useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";

const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const product_items = useFetch({
    method: "GET",
    url: "https://rdqcms.ciscalautomation.com/api/products",
  });

  const product_categories = useFetch({
    method: "GET",
    url: "https://rdqcms.ciscalautomation.com/api/categories",
  });

  const product_brands = useFetch({
    method: "GET",
    url: "https://rdqcms.ciscalautomation.com/api/brands",
  });

  return (
    <GlobalContext.Provider
      value={{ product_items, product_categories, product_brands }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
